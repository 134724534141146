import Base from '../base';
import Uploader from '../../components/Uploader';

export default class New extends Base {
  load() {
    this.uploader = new Uploader(
      '#assets-dropzone',
      {
        railsApi: {
          initUploadUrl: Routes.init_upload_admin_assets_path(),
          authenticityToken: gon.authenticity_token
        }
      }
    );
  }

  destroy() {
    if (this.uploader) {
      this.uploader.destroy();
    }
  }
}

import I18n from 'i18n-js/index.js.erb';
import Base from './base';
import Modal from '../components/Modal';
import { spinner } from '../components/Spinner';
import { initTippy } from '../components/Tippy';

export default class Application extends Base {
  load() {
    this.setApplicationLocale();
    this.initializeMainModal();
    this.initializebSmartModal();
    this.initModalAnchors();

    $(document).on('mouseover', 'body', () => { initTippy(); });
  }

  beforeCache() {
    $('div.tooltip[role="tooltip"]').remove();
    $('.modal-backdrop').remove();
    $('.ui-helper-hidden-accessible').remove(); // Some js append this strange HTML into DOM
    // $('.flash-messages').remove();
  }

  destroy() {
    this.destroyMainModal();
    this.destroybSmartModal();
    $('a[data-modal]').off('click');
  }

  initializeMainModal() {
    this.mainModal = new Modal({ domId: 'application-main-modal' });
    this.mainModal.$Footer.hide();
  }

  destroyMainModal() {
    if (this.mainModal && this.mainModal.elementExists()) {
      this.mainModal.destroy();
      this.mainModal = undefined;
    }
  }

  initializebSmartModal() {
    this.bSmartModal = new Modal({ domId: 'application-bsmart-modal', style: 'bsmart-player', emptyOnClose: true });
    this.bSmartModal.$Footer.hide();
  }

  destroybSmartModal() {
    if (this.bSmartModal && this.bSmartModal.elementExists()) {
      this.bSmartModal.destroy();
      this.bSmartModal = undefined;
    }
  }

  setApplicationLocale() {
    I18n.defaultLocale = gon.default_locale;
    I18n.locale = gon.locale;
  }

  handleModalAnchorClick(e) {
    e.preventDefault();
    e.stopPropagation();

    const $target = $(e.target);
    const $a = $target.data('modal') ? $target : $target.parents('[data-modal]').first();

    const bSmartModal = $a.data('bsmart');
    const noDialog = $a.data('nodialog');

    const modalToUse = bSmartModal ? this.bSmartModal : this.mainModal;

    const title = $a.data('modal-title') && $a.data('modal-title').length > 0
      ? $a.data('modal-title')
      : $a.attr('title');

    modalToUse.updateTitle(title);
    modalToUse.updateContent(spinner());
    if (!noDialog) {
      modalToUse.open();
    }

    $.get($a.attr('href')).done((content) => {
      modalToUse.updateContent(content);
    });
  }

  initModalAnchors() {
    $('a[data-modal]').on('click', this.handleModalAnchorClick.bind(this));
  }
}

import _ from 'lodash';

export default class Selectable {
  constructor(dt) {
    this.dt = dt;
    this.initializeHeadCheckboxes();
    this.initializeRowCheckboxes();
    this.initialSelection();
    this.headUnchecked();
  }

  get $headCheckboxes() {
    return this.dt.$.find('input.DataTable__headCheckbox');
  }

  get $checkboxes() {
    return this.dt.$.find('input.DataTable__checkbox');
  }

  get selectedIds() {
    return this.dt.selectedIds;
  }

  pushSelectedId(id) {
    if (this.isSelected(id)) { return; }
    this.selectedIds.push(id);
    this.dt.triggerSelectionEvent();
  }

  popSelectedId(id) {
    if (!this.isSelected(id)) { return; }

    _.remove(this.selectedIds, (n) => (n === id));
    this.dt.triggerSelectionEvent();
  }

  isSelected(id) {
    return _.includes(this.selectedIds, id);
  }

  findInput(id) {
    return $(`input[data-asset-id="${id}"]`);
  }

  findRow(id) {
    return $(`tr[data-asset-id="${id}"]`);
  }

  select(id) {
    this.pushSelectedId(id);
    const $input = this.findInput(id);
    $input.prop('checked', true);
    this.findRow(id).addClass('DataTable__row--selected');
  }

  selectAll() {
    this.headChecked();
    this.dt.availableIds.forEach((id) => {
      this.select(id);
    });
  }

  deselect(id) {
    this.headUnchecked();
    this.popSelectedId(id);
    this.findInput(id).prop('checked', false);
    this.findRow(id).removeClass('DataTable__row--selected');
  }

  deselectAll() {
    this.headUnchecked();
    this.dt.availableIds.forEach((id) => {
      this.deselect(id);
    });
  }

  toggle(id) {
    if (this.isSelected(id)) {
      this.deselect(id);
    } else {
      this.select(id);
    }
  }

  toggleAll() {
    if (this.headCheckboxSelection) {
      this.deselectAll();
    } else {
      this.selectAll();
    }
  }

  headUnchecked() {
    this.headCheckboxSelection = false;
    this.$headCheckboxes.prop('checked', false);
  }

  headChecked() {
    this.headCheckboxSelection = true;
    this.$headCheckboxes.prop('checked', true);
  }

  handleHeadChange(e) {
    e.preventDefault();
    e.stopPropagation();

    this.toggleAll();
  }

  handleRowChange(e) {
    e.preventDefault();
    e.stopPropagation();

    const $input = $(e.target);
    const id = $input.data('assetId');

    this.toggle(id);
  }

  buildHeadCheckbox() {
    const $checkbox = $('<input type="checkbox" class="DataTable__headCheckbox" value="1"">');
    const $cell = $('<th />');

    $checkbox.on('change', this.handleHeadChange.bind(this));
    $cell.append($checkbox);

    return $cell;
  }

  buildRowCheckbox($row) {
    const id = $row.data('assetId');
    const $checkbox = $(`<input type="checkbox" class="DataTable__checkbox" value="1" data-asset-id="${id}">`);
    const $cell = $('<td />');

    $checkbox.on('change', this.handleRowChange.bind(this));
    $cell.append($checkbox);

    return $cell;
  }

  initializeRowCheckboxes() {
    this.dt.$bodyRows.each((idx, tr) => {
      const $tr = $(tr);
      const $cell = this.buildRowCheckbox($tr);
      $tr.prepend($cell);
    });
  }

  initializeHeadCheckboxes() {
    this.dt.$headRows.each((idx, tr) => {
      const $tr = $(tr);
      const $cell = this.buildHeadCheckbox($tr);
      $tr.prepend($cell);
    });
  }

  initialSelection() {
    this.dt.selectedIds.forEach((id) => {
      this.select(id);
    });
  }

  destroy() {
    this.$checkboxes.off('change');
    this.dt = undefined;
  }
}

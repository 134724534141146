export default {
  initialize(browse) {
    this.browse = browse;
    // TODO: understand this use case
    // $(document).on('tree:sidebar:load', () => { this.handleSidebarLoad(); });

    $(document).on('tree:node:changed', this.handleNodeChanged.bind(this));
    $(document).on('tree:node:nameChanged', this.handleNodeNameChanged.bind(this));
    $(document).on('tree:childNodes:sorted', this.handleChildNodesSorted.bind(this));
    $(document).on('tree:node:deleted', this.handleNodeDeleted.bind(this));

    // $(document).on('click', 'a[data-new-child]', (e) => {
    //   const node = this.tree.findNode($(e.target).data('new-child'));
    //   this.showNewChildModal(node);
    //   e.preventDefault();
    // });
  },

  destroy() {
    $(document).off('tree:node:changed', this.handleNodeChanged);
    $(document).off('tree:node:nameChanged', this.handleNodeNameChanged);
    $(document).off('tree:childNodes:sorted', this.handleChildNodesSorted);
    $(document).off('tree:node:deleted', this.handleNodeDeleted);
  },

  handleNodeChanged(e, node, childNode) {
    this.browse.tree.reloadNode(node.uuid, (treeNode) => {
      treeNode.setExpanded(true);

      // If the sidebar is open with the changed node or its parent, reload the sidebar content
      const parentUuid = (treeNode.parent || {}).data && treeNode.parent.data.uuid;
      const sidebarNodeUuid = this.browse.state.sidebarNode && this.browse.state.sidebarNode.uuid;
      const childNodeUuid = childNode && childNode.uuid;

      if (sidebarNodeUuid === node.uuid || sidebarNodeUuid === parentUuid || sidebarNodeUuid === childNodeUuid) {
        this.browse.reloadSidebar();
      }

      if (childNode) {
        this.browse.tree.setCurrent(childNodeUuid);
      }

      // Reload the current datatable if is changed the current node
      if (childNode) {
        this.browse.visitNode(childNode);
      } else if (node.uuid === this.browse.state.current_uuid) {
        this.browse.visitNode(node);
      }
    });
  },

  handleNodeNameChanged(e, node, newName) {
    const parentUuid = (node.parent || {}).data && node.parent.data.uuid;
    if(!parentUuid){ $('.root-info__name h2').text(newName); }
    $('.editable-bundle-general-title').text(newName);
    node.setTitle(newName);
  },

  handleChildNodesSorted(e, node) {
    this.browse.tree.reloadNode(node.uuid, treeNode => {
      treeNode.setExpanded(true);
    });
  },

  handleNodeDeleted(e, node, parent) {
    this.browse.visitNode(parent);
    this.browse.tree.removeNode(node.uuid);
  }
};

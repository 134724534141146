import Base from '../base';
import Uploader from '../../components/Uploader';

export default class New extends Base {
  load() {
    this.uploader = new Uploader(
      '#legacy-import-dropzone',
      {
        maxFiles: 1,
        railsApi: {
          initUploadUrl: gon.init_upload_admin_legacy_imports_url,
          stopUploadUrl: gon.stop_upload_admin_legacy_imports_url,
          authenticityToken: gon.authenticity_token
        }
      }
    ).on("queuecomplete", function(progress) {
      Turbolinks.visit(Routes.admin_legacy_imports_path({notice: 'just_uploaded'}));
    });
  }

  destroy() {
    if (this.uploader) {
      this.uploader.destroy();
    }
  }
}

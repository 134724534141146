export const parseTree = (node) => {
  const hasChildren = node.children && node.children.length > 0;
  const childrenReducer = (accumulator, child) => {
    accumulator.push(parseTree(child));
    return accumulator;
  };
  const children = hasChildren ? node.children.reduce(childrenReducer, []) : [];

  return {
    title: node.name,
    name: node.name,
    model_id: node.id,
    typology: node.typology,
    uuid: node.uuid,
    id: node.uuid,
    folder: node.typology === 'group_typology' || node.typology === 'root_typology',
    lazy: node.typology != 'wrapper_typology',
    cache: false,
    children,
  };
};

import Base from '../base';
import Editables from '../../components/Editables';

export default class Edit extends Base {
  load() {
    new Editables(
      '.editable-resource_template-attribute',
      {
        params: function(params) {
          //originally params contain pk, name and value
          params = {
            authenticity_token: gon.authenticity_token,
            resource_template: {
              id: params.id,
              [params.name]: params.value
            }
          };
          return params;
        }
      }
    );

    new Editables(
      '.editable-rule-attribute',
      {
        params: function(params) {
          //originally params contain pk, name and value
          params = {
            authenticity_token: gon.authenticity_token,
            rule: {
              id: params.id,
              [params.name]: params.value
            }
          };
          return params;
        }
      }
    );

    new Editables(
      '.editable-rule-types-attribute',
      {
        tpl: '<select multiple></select>',
        params: function(params) {
          params = {
            authenticity_token: gon.authenticity_token,
            rule: { [params.name]: params.value.concat(['']) } // NOTE: empty string avoids deletion
          };
          return params;
        },
        display: function(value) {
          var selected = JSON.parse(value).map(function(id){
            return gon.selectable_types.find(function(x){ return x.id == id;}).text.toString();
          });
          if(selected.length) {
            $(this).html(selected.join(', '));
          } else {
            $(this).empty();
          }
        }
      }
    );

    $('.editable-rule-types-attribute').on('shown', function(event, editable){
      editable.input.$input.select2({
        theme: 'bootstrap4',
        width: '200', // NOTE: actually a random number
        minimumResultsForSearch: Infinity,
        multiple: true,
        placeholder: editable.options.placeholder,
        data: gon.selectable_types
      }).val(JSON.parse(editable.value)).trigger('change');
    });

    $('#rule_types').select2({
      theme: 'bootstrap4',
      width: '100%'
    });
  }

  destroy() {
    if (this.uploader) {
      this.uploader.destroy();
    }
  }
}

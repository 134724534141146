import Awesomplete from 'awesomplete';

const defaultOptions = {
  // Note regexps allow empty match so we won't have to handle nulls.
  regexpBeforeCursor: /\S*$/,
  regexpAfterCursor: /^\S*/,
  minChars: 3,
  filter: function(text, input) {
    // Match around cursor in two pieces using given regexp.
    var match = {
      before: input.substring(0, this.input.selectionStart).match(this.regexpBeforeCursor),
      after: input.substring(this.input.selectionStart, input.length).match(this.regexpAfterCursor)
    };
    match.whole = match.before + match.after;
    // If match is long enough, cache it and confirm it; discard it otherwise.
    if (match.whole.length < this.minChars) {
      return false;
    } else {
      this.matchesCache[text] = match;
      return Awesomplete.FILTER_CONTAINS(text, match.whole);
    }
  },
  item: function(text, input) {
    // Return a <li> with the <mark> highlighted match.
    return Awesomplete.ITEM(text, this.matchesCache[text].whole);
  },
  replace: function(text) {
    // Recover the match from the cache.
    var match = this.matchesCache[text];
    // Extract head and tail of input value excluding match; assign value with replacement.
    var value = this.input.value;
    var beforeMatch = value.substring(0, match.before.index);
    var afterMatch = value.substring(match.before.index + match.whole.length, value.length);
    this.input.value = beforeMatch + text + afterMatch;
    // Place the cursor at the end of the replacement.
    var newCursorPosition = (beforeMatch + text).length;
    this.input.selectionStart = newCursorPosition;
    this.input.selectionEnd = newCursorPosition;
  }
};

export default class Autocompleter extends Awesomplete {
  constructor(element, options) {
    var mergedOptions = Object.assign(Object.assign({}, defaultOptions), options);
    super(element, mergedOptions);
    this.regexpBeforeCursor = mergedOptions.regexpBeforeCursor;
    this.regexpAfterCursor = mergedOptions.regexpAfterCursor;
    this.matchesCache = {};
  }
}

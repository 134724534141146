import { ajaxHaders, handleAjaxFailure } from '../utils/ajax';

const defaultParams = (params) => (Object.assign({ format: 'json' }, params));

export const bulkDeleteRequest = (asset_ids, params = {}) => {
  const dataType = params.dataType || params.format || 'json';

  return $.ajax({
    headers: ajaxHaders(),
    url: Routes.bulk_destroy_admin_assets_path (defaultParams(params)),
    method: 'POST',
    dataType,
    data: {
      _method: 'delete',
      asset_ids
    }
  }).fail(handleAjaxFailure);
};

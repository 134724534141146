import mySortable from 'sortablejs';
import _ from 'lodash';

import { arrangeNodesRequest } from '../../../requests/bundles';

export default class SortableGrid {
  constructor(selector, bundle_id, current_uuid) {
    this.selector = selector;
    this.bundle_id = bundle_id;
    this.current_uuid = current_uuid;

    const container = document.querySelector(selector);

    if($(container).length){
      this.drake = mySortable.create(container, {
        animation: 200, // ms, animation speed moving items when sorting, `0` — without animation
        onStart: e => {
          e.target.classList.remove('use-hover');
        },
        onEnd: e => {
          e.target.classList.add('use-hover');
          this.handleDragend();
        },
        ghostClass: 'sortable-ghost',  // Class name for the drop placeholder
        chosenClass: 'sortable-chosen' // Class name for the chosen item
      });
    }
  }

  get uuids() {
    const elements = Object.values($(this.selector).find('.thumb-grid__element a'));
    return _.compact(elements.map((e) => ($(e).data('uuid'))));
  }

  submitArrange() {
    arrangeNodesRequest(this.bundle_id, this.current_uuid, this.uuids).done((payload) => {
      // console.log('Arrangement done!', payload);
      $(document).trigger('tree:childNodes:sorted', [payload.node]);
    });
  }

  handleDragend() {
    this.submitArrange();
  }

  destroy() {
    if(this.drake) {
      this.drake.destroy();
      this.drake = undefined;
    }
  }
}

import SortableGrid from './SortableGrid';

const GRID_CONTAINER = '.thumb-grid';
const GRID_ELEMENTS = '.thumb-grid__element';
const BUNDLE_ELEMENTS = '.thumb-grid__element--bundle';
const ASSET_ELEMENTS = '.thumb-grid__element--asset';

export default class ThumbGrid {
  constructor(browse) {
    this.browse = browse;
    this.initializeSortableGrid();
    this.initializeElementEvents();
    this.initializeTooltips();
  }

  handleBundleLinkClick(e) {
    e.preventDefault();
    e.stopPropagation();
    const $a = $(e.currentTarget);
    const node = this.browse.tree.findNode($a.data('uuid')).data;
    this.browse.visitNode(node);
  }

  initializeSortableGrid() {
    if (this.browse.state.readOnly) { return; }
    if (this.$gridContainer.length === 0) { return; }
    this.sortableGrid = new SortableGrid(GRID_CONTAINER, this.bundle_id, this.current_uuid);
    this.enableDragHandles();
  }

  enableDragHandles() {
    this.$bundleElements.addClass('thumb-grid__element--movable');
  }

  disableDragHandles() {
    this.$bundleElements.removeClass('thumb-grid__element--movable');
  }

  initializeElementEvents() {
    this.$bundleElements.find('a[data-uuid]').on('click', this.handleBundleLinkClick.bind(this));
    this.$gridElements.find('.element__media').on('click', function() {
      $(this).closest('.thumb-grid__element').find('a div').click();
    });
  }

  initializeTooltips() {
    $(document).ready(function() {
      $(`${GRID_ELEMENTS} .lower-band__name`).each(function() {
        // if text truncated add tooltip
        if(this.offsetWidth < this.scrollWidth && !$(this).attr('data-toggle')) {
          $(this).attr('data-toggle', 'tooltip');
          if(!$(this).attr('title')) {
            $(this).prop('title', $(this).text());
          }
        }
      });
    });
  }

  get $gridContainer() {
    return $(GRID_CONTAINER);
  }

  get $gridElements() {
    return $(GRID_ELEMENTS);
  }

  get $bundleElements() {
    return $(BUNDLE_ELEMENTS);
  }

  get $assetElements() {
    return $(ASSET_ELEMENTS);
  }

  get bundle_id() {
    return this.browse.state.bundle_id;
  }

  get current_uuid() {
    return this.browse.state.current_uuid;
  }

  destroy() {
    this.$gridElements.find('a[data-uuid]').off('click');
    this.$gridElements.find('.element__media').off('click');

    if (this.sortableGrid) {
      this.sortableGrid.destroy();
      this.sortableGrid = undefined;
    }
  }
}

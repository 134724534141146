// Import stylesheets (available with stylesheet_pack_tag)
import '../styles/application'

// Import images (available with image_pack_tag)
require.context('../images', true, /\.(jpg|png)$/) // true means recursive

require("@rails/ujs").start()
require("turbolinks").start()

// NOTE: bootstrap must be imported before admin-lte
import 'bootstrap'

// import 'clipboard' // TODO: admin-lte dependency -- required?
// import 'fastclick' // TODO: admin-lte dependency -- required?
// import 'jquery-slimscroll' // TODO: admin-lte dependency -- required?
// import 'icheck' // TODO: admin-lte dependency -- required?
import 'admin-lte'

// NOTE: tempusdominus is required by bootstrap-editable
// NOTE: webpack provides the required moment
import 'tempusdominus-bootstrap-4'

// TODO: inline datetimepickers still aren't working properly
// NOTE: webpack provides the required window.jQuery
import "../vendor/x-editable-bs4/js/bootstrap-editable"

// TODO: do we need select2 locales?
// TODO: is this working?
import 'select2'

// TODO: is this working?
import 'jquery-contextmenu'

// TODO: is this working?
import 'jquery-ui/ui/widget.js'
import 'jquery-ui/ui/widgets/mouse.js'
import 'jquery-ui/ui/widgets/selectable.js'

// TODO: is this working?
import 'jquery-ui/ui/unique-id'
import 'jquery-ui/ui/keycode'
import 'jquery-ui/ui/effect'
import 'jquery-ui/ui/effects/effect-blind'
import 'jquery.fancytree'
import 'jquery-contextmenu'
import '../vendor/fancytree/contextMenu.ext'

// TODO: is this working?
import I18n from '../i18n-js/index.js.erb'
window.I18n = I18n

import Routes from '../js-routes.js.erb'
window.Routes = Routes

// NOTE: this is an hack needed by in-page JS using jQuery (webpack provides window.jQuery)
window.$ = window.jQuery

// TODO: check whether this is actually working
import { getParsedRes, checkProjectIsExtracted } from 'resources-loader'
global.checkProjectIsExtracted = checkProjectIsExtracted
global.getParsedRes = getParsedRes

//==============================================================================

import bootApp from '../boot';

bootApp();

import * as d3 from 'd3';
import dragula from 'dragula';

import Base from '../../base';
import Uploader from '../../../components/Uploader';
import Dendrogram from '../../../components/Dendrogram';
import Sidebar from '../../../components/Sidebar';
import Modal from '../../../components/Modal';
import Spinner from '../../../components/Spinner';
import MoveNodeModal from '../../../components/MoveNodeModal';
import { ajaxHaders } from '../../../utils/ajax';
import {
  fetchBundleSubtree,
  createChildRequest,
  fetchNodeSidebar,
  removeNodeRequest
} from '../../../requests/bundles';
import { nodeContextMenu } from './context_menu';

export default class Structure extends Base {
  get arrangeForm() {
    return $('#arrange-nodes-form');
  }

  arrangeFormRequest() {
    const data = this.arrangeForm.serialize();
    const url = this.arrangeForm.prop('action');

    console.log(data);

    return $.ajax({
      url: url,
      data: data,
      method: 'POST',
      dataType: 'json',
      headers: ajaxHaders()
    }).fail((e) => {
      this.modal.updateContent('Error: Something went wrong...');
      console.error(e.responseText); // eslint-disable-line
    });
  }

  // TODO: consider to create a self contained component class for the
  // NewChildModal. See `MoveNodeModal` as example.
  handleNewChildFormSubmit(node) {
    const $form = this.modal.$.find('form');
    const $submit = $('#newChildSubmit');
    const data = `${$form.serialize()}&parent_uuid=${node.data.uuid}`;

    $submit.prop('disabled', true);

    createChildRequest(gon.bundle_id, data)
      .always(() => { $submit.prop('disabled', false); })
      .done((payload) => {
        if (payload.status === 'ok') {
          $(document).trigger('tree:node:changed', [payload.child]);
          this.tree.addNode(node, payload.child);
          this.modal.close();
        } else {
          this.modal.updateContent(payload.content);
        }
      });
  }

  handleRemoveNode(node) {
    removeNodeRequest(gon.bundle_id, node.data.uuid)
      .done((payload) => {
        if (this.state.sidebarNode && this.state.sidebarNode.data.uuid === node.data.uuid) {
          this.closeSidebar();
        }

        $(document).trigger('tree:node:changed', [payload.node]);
      });
  }

  handleArrangeSubmit() {
    this.arrangeFormRequest()
      .done((payload) => {
        $(document).trigger('tree:node:changed', [payload.node]);
      });
  }

  // Dedrogram animations for a moved node
  // @param {Object} node - moved plain node datum from api
  // @param {Object} destNode - destination node datum from api
  handleNodeMoved(node, destNode) {
    $(document).trigger('tree:node:changed', [destNode]);
    this.tree.removeNode(node.uuid);
  }

  initializeNodesArrange() {
    const container = document.getElementById('arrange-nodes');
    if (container === null) { return; }
    this.dragulaHandler = dragula([container], { direction: 'vertical' });

    this.arrangeForm.on('submit', (e) => {
      e.preventDefault();
      this.handleArrangeSubmit();
    });
  }

  destroyNodesArrange() {
    if (this.dragulaHandler) {
      this.dragulaHandler.destroy();
      this.dragulaHandler = undefined;
    }

    this.arrangeForm.off('submit');
  }

  handleSidebarLoad() {
    this.destroyNodesArrange();
    this.initializeNodesArrange();
  }

  // TODO: move the modal logic into a proper component
  showNewChildModal(node) {
    const modalButtons = [
      {
        content: I18n.t('admin.actions.close'),
        onClick: 'close',
        className: 'btn btn-default'
      },
      {
        content: I18n.t('admin.actions.create'),
        onClick: (e) => { e.preventDefault(); this.handleNewChildFormSubmit(node); },
        className: 'btn btn-primary',
        id: 'newChildSubmit'
      }
    ];

    this.modal.update({
      title: I18n.t('admin.nodes.new_child', { name: node.data.name }),
      content: this.spinner.render(),
      footerButtons: modalButtons
    });

    this.modal.open();

    $.get(Routes.new_child_admin_bundle_path(gon.bundle_id))
      .fail(() => { this.modal.updateContent('Error: Something went wrong...'); })
      .done((payload) => {
        this.modal.updateContent(payload);
        this.modal.$.find('form').on('submit', (e) => {
          e.preventDefault();
          this.handleNewChildFormSubmit(node);
        });
      });
  }

  showMoveChildModal(node) {
    this.moveNodeModal.loadNode(node.data);
  }

  reloadNode(node) {
    fetchBundleSubtree({ bundle_id: gon.bundle_id, uuid: node.uuid })
      .done((payload) => { this.tree.updateNode(payload.paths, node.uuid); });
  }

  // ACTION initialization
  load() {
    const $contaiter = $('#dendrogram').parent();
    const svg = d3.select('#dendrogram');

    svg.attr('height', 2000);
    svg.attr('width', $contaiter.width());

    this.sidebar = new Sidebar();
    this.modal = new Modal();
    this.moveNodeModal = new MoveNodeModal({
      bundle_id: gon.bundle_id,
      onMove: (node, oldParent, newParent) => { this.handleNodeMoved(node, oldParent, newParent); }
    });
    this.spinner = new Spinner();
    this.tree = new Dendrogram(svg, gon.paths, {
      onNodeClick: (node) => { this.tree.toggleNode(node); },
      nodeContextMenu: nodeContextMenu(this)
    });

    this.tree.render();

    // Initialize Structure Action custom events
    $(document).on('tree:sidebar:load', () => { this.handleSidebarLoad(); });
    $(document).on('tree:node:changed', (e, node) => {
      this.reloadNode(node);

      const treeNode = this.tree.findNode(node.uuid);
      const parentUuid = treeNode.parent && treeNode.parent.data.uuid;
      const sidebarNodeUuid = this.state.sidebarNode && this.state.sidebarNode.data.uuid;

      if (sidebarNodeUuid && sidebarNodeUuid === node.uuid || sidebarNodeUuid === parentUuid) {
        this.reloadSidebar();
      }
    });
    $(document).on('click', 'a[data-new-child]', (e) => {
      const node = this.tree.findNode($(e.target).data('new-child'));
      this.showNewChildModal(node);
      e.preventDefault();
    });

    this.state = {
      sidebarNode: null
    };
  }

  showSidebar(node) {
    this.state.sidebarNode = node;

    if (!node) {
      this.closeSidebar();
      return;
    }

    this.sidebar.updateContent(this.spinner.render());
    fetchNodeSidebar(gon.bundle_id, node.data.uuid)
      .done((content) => {
        this.sidebar.updateContent(content);
        this.sidebar.uploader = new Uploader(
          '#assets-dropzone',
          {
            railsApi: {
              initUploadUrl: Routes.init_upload_admin_assets_path({resource_uuid: node.data.uuid}),
              authenticityToken: gon.authenticity_token
            }
          }
        );
      });

    this.sidebar.open();
  }

  closeSidebar() {
    this.state.sidebarNode = null;
    if(this.sidebar.updloader){
      this.sidebar.uploader.destroy();
    }
    this.sidebar.close();
  }

  reloadSidebar() {
    if (!this.state.sidebarNode) { return; }
    if(this.state.sidebarNode.uuid !== this.state.current_uuid){
      this.showSidebar(this.state.sidebarNode);
    }
  }

  // ACTION destroy
  destroy() {
    $(document).off('tree:sidebar:load');
    $(document).off('tree:node:changed');
    $(document).off('click', 'a[data-new-child]');

    this.destroyNodesArrange();

    if (this.sidebar) {
      this.closeSidebar();
      this.sidebar.destroy();
      this.sidebar = undefined;
    }

    if (this.tree) {
      this.tree.destroy();
      this.tree = undefined;
    }

    if (this.modal) {
      this.modal.destroy();
      this.modal = undefined;
    }

    if (this.moveNodeModal) {
      this.moveNodeModal.destroy();
      this.moveNodeModal = undefined;
    }

    if (this.uploader) {
      this.uploader.destroy();
    }
  }
}

// Infer a reasonable content type from the extension on bad browsers.
export const inferType = (file) => {
  var isBogus = file.type == 'application/octet-stream';
  var isBlank = file.type == '';
  var isUndef = file.type == undefined;
  if (isBogus || isBlank || isUndef) {
    switch ((file.name.split('.').pop() || '').toLowerCase()) {
    case 'avi': return 'video/avi';
    case 'gz': return 'application/gzip';
    case 'jpeg': return 'image/jpeg';
    case 'jpg':  return 'image/jpeg';
    case 'json': return 'application/json';
    case 'mp3': return 'audio/mp3';
    case 'mp4': return 'video/mp4';
    case 'ogg': return 'audio/ogg';
    case 'png': return 'image/png';
    case 'srt': return 'application/x-subrip';
    case 'xml': return 'application/xml';
    case 'zip': return 'application/zip';
    case 'swf': return 'application/x-shockwave-flash';
    default: return file.type;
    }
  } else {
    return file.type;
  }
};

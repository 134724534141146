import Base from '../base';
import PillsMetaSearch from '../../components/PillsMetaSearch';

export default class Index extends Base {
  load() {
    this.pillsMetaSearch = new PillsMetaSearch('.meta-container', '#meta_search_form_query_string', '#meta_search_form_combinator');
  }

  destroy() {
    if (this.pillsMetaSearch) {
      this.pillsMetaSearch.destroy();
      this.pillsMetaSearch = undefined;
    }
  }
}

import tippy from 'tippy.js';

const defaultOptions = {
  arrow: true,
  arrowTransform: 'scale(0.8) translateY(-1px)',
  arrowType: 'sharp',
  // trigger: 'click',
  updateDuration: 0,
  popperOptions: {
    modifiers: {
      preventOverflow: {
        enabled: false
      },
      hide: {
        enabled: false
      }
    }
  }
};

class Tippy  {
  constructor(selector, options) {
    var instanceOptions = Object.assign(Object.assign(Object.assign({}, defaultOptions)), options);
    tippy(selector, instanceOptions);
  }
}

export const initTippy = () => {
  new Tippy('body', { target: '[data-toggle="tooltip"]'});
};

import Base from '../../base';
import { parseTree } from './utils';
import eventsListener from './eventsListener';
import GroupToolbar from './GroupToolbar';
import Datatable from './Datatable';
import ThumbGrid from './ThumbGrid';
import makeContextMenu from './makeContextMenu';
import Treeview from '../../../components/Treeview';
import Sidebar from '../../../components/Sidebar';
// import Uploader from '../../../components/Uploader';
import { spinner } from '../../../components/Spinner';
import MoveNodeModal from '../../../components/MoveNodeModal';
import NewChildNodeModal from '../../../components/NewChildNodeModal';
import AddResourcesModal from '../../../components/AddResourcesModal';
import BlockingProgressModal from '../../../components/BlockingProgressModal';
import _ from 'lodash';
import {
  fetchBundleSubtree,
  fetchNodeSidebar,
  fetchNodeContent,
  duplicateRequest,
  removeNodeRequest,
} from '../../../requests/bundles';
import Editables from '../../../components/Editables';
import QueryMetaSearch from '../../../components/QueryMetaSearch';

const TREEVIEW_SELECTOR = '#treeview-node';
const INITIAL_STATE = {
  sidebarNode: null,
  bundle_id: null
};

const CLIPBOARD_STORAGE_ITEM = 'dam.clipboard';

export default class Browse extends Base {
  // closeSidebar() {
  //   this.state.sidebarNode = null;
  //   // this.destroyUploader();
  //   this.sidebar.close();
  // }

  showSidebar(node) {
    this.state.sidebarNode = node;

    // if (!node) {
    //   this.closeSidebar();
    //   return;
    // }

    this.sidebar.updateContent(spinner());

    fetchNodeSidebar(this.state.bundle_id, node.uuid)
      .done((content) => {
        this.sidebar.updateContent(content);
        // this.initializeUploader(node);
        $('.Sidebar').find('select').select2({
          theme: 'bootstrap4'
          // minimumResultsForSearch: -1
        });
        new Editables(
          '.editable-bundle-meta-datum',
          {
            params: function(params) {
              //originally params contain pk, name and value
              params = {
                authenticity_token: gon.authenticity_token,
                values_attributes: [
                  {
                    id: this.dataset.valueId,
                    [`value_${this.dataset.valueType}`]: params.value
                  }
                ]
              };
              return params;
            },
            // mode: 'inline'
          }
        );


        // TODO: this is kind of hackish - refactor?
        const node = this.tree.findNode(this.state.current_uuid);
        new Editables(
          '.editable-bundle-meta-datum--general-title',
          {
            params: function(params) {
              //originally params contain pk, name and value
              params = {
                authenticity_token: gon.authenticity_token,
                values_attributes: [
                  {
                    id: this.dataset.valueId,
                    [`value_${this.dataset.valueType}`]: params.value
                  }
                ]
              };
              return params;
            },
            success: (response, newValue) => {
              $(document).trigger('tree:node:nameChanged', [node, newValue]);
            }
            // mode: 'inline'
          }
        );

        if(this.state.readOnly) {
          this.disableSidebarEditing();
        }
      });

    // this.sidebar.open();
  }

  reloadSidebar() {
    if (!this.state.sidebarNode) { return; }
    if(this.state.sidebarNode.uuid !== this.state.current_uuid){
      this.showSidebar(this.state.sidebarNode);
    }
  }

  showNewChildModal(node) {
    this.newChildNodeModal.loadNode(node);
  }

  showMoveChildModal(node) {
    this.moveNodeModal.loadNode(node);
  }

  handleRemoveNode(node, parent) {
    if (!confirm(I18n.t('admin.general.are_you_sure'))) { return; }

    removeNodeRequest(this.state.bundle_id, node.uuid)
      .done(() => {
        if (parent.id) { // subtree deleted
          $(document).trigger('tree:node:deleted', [node, parent]);
        } else { // tree emptied
          $(document).trigger('tree:node:changed', [node]);
        }
      });
  }

  handleAddResources(node) {
    this.addResourcesModal.loadNode(node);
  }

  handleDuplicate(node, targetParent) {
    // TODO: replace JS confirm with confirmation modal
    if(!confirm(`Stai per duplicare "${node.name}" in "${targetParent.name}". Sei sicuro?`)) { return; }
    duplicateRequest({ source_uuid: node.uuid, target_parent_uuid: targetParent.uuid }).
      done((data) => {
        this.blockingProgressModal.watch(data.jid).
          then((result) => {
            this.handleChildNodeCreated(result.target, targetParent);
          });
      });
  }

  handleClipboardCopy(node) {
    localStorage.setItem(CLIPBOARD_STORAGE_ITEM, JSON.stringify(node));
  }

  handleClipboardPaste(targetParent) {
    var node = JSON.parse(localStorage.getItem(CLIPBOARD_STORAGE_ITEM));
    this.handleDuplicate(node, targetParent);
  }

  handleContextMenuClick(node, action) {
    switch (action) {
    case 'info':
      return this.showSidebar(node.data);
    case 'new_child':
      return this.showNewChildModal(node.data);
    case 'new_sibling':
      return this.showNewChildModal(node.parent.data);
    case 'move_node':
      return this.showMoveChildModal(node.data);
    case 'remove_node':
      return this.handleRemoveNode(node.data, node.parent.data);
    case 'add_resources':
      return this.handleAddResources(node.data);
    case 'duplicate':
      return this.handleDuplicate(node.data, node.parent.data);
    case 'clipboard_copy':
      return this.handleClipboardCopy(node.data);
    case 'clipboard_paste':
      return this.handleClipboardPaste(node.data);
    default:
      console.log('Unhandled context menu'); // eslint-disable-line
    }
  }

  handleNodeLazyLoad(event, data) {
    const node = data.node;
    const deferred = new $.Deferred();

    data.result = deferred.promise();
    fetchBundleSubtree({ bundle_id: this.state.bundle_id, uuid: node.data.uuid })
      .done((result) => {
        deferred.resolve(parseTree(result.paths).children || []);
      });

    return data;
  }

  handleNodeMoved(node, destNode) {
    $(document).trigger('tree:node:changed', [destNode, node]);
    this.tree.removeNode(node.uuid);
    this.moveNodeModal.close();
  }

  handleChildNodeCreated(node, parent) {
    $(document).trigger('tree:node:changed', [parent, node]);
    this.newChildNodeModal.close();
  }

  handleAddedResources(parent, nodes) {
    this.addResourcesModal.close();
    $(document).trigger('tree:node:changed', [parent]);
  }

  handleMenuClick(event, data) {
    const node = data.node.data;
    this.visitNode(node);
  }

  visitNode(node) {
    if(node.uuid !== this.state.current_uuid) {
      const uuid = node.uuid;
      $('.three-panes__central-pane .central-pane__body').html(spinner());
      this.showSidebar(node);
      fetchNodeContent(this.state.bundle_id, uuid).done((body) => {
        this.updateContent(node, uuid, body);
      });
    }
  }

  updateContent(node, uuid, body) {
    // Update current state
    this.state = Object.assign(this.state, { current_uuid: uuid });

    // Update html
    $('.three-panes__central-pane').html($(body).html());
    this.initializeGroupToolbar();
    this.showCurrentVisualizationTypeElement();
    this.initializeDatatable();
    this.initializeThumbGrid();

    // Replace URL
    const path = Routes.browse_admin_bundle_path(this.state.bundle_id, { uuid: uuid });
    const newUrl = `${window.location.protocol}//${window.location.host}${path}`;
    Turbolinks.controller.history.push(newUrl);
    this.tree.setCurrent(uuid);
  }

  initializeTree() {
    const initialState = parseTree(gon.paths);
    initialState.expanded = true;

    this.tree = new Treeview(TREEVIEW_SELECTOR, {
      source: [initialState],
      lazyLoad: this.handleNodeLazyLoad.bind(this),
      contextMenu: makeContextMenu(this),
      onContextMenu: this.handleContextMenuClick.bind(this),
      click: (e, data) => {
        if(data.targetType === 'title') {
          this.handleMenuClick(e, data);
        }
      },
      extensions: ['glyph'],
    }, {
      glyph: {
        preset: 'material',
        map: {}
      },
      minExpandLevel: 2,
      icon: function(e, data) {
        const node = data.node;
        if(node.parent && node.parent.parent === null) {
          node.addClass('fancytree-node--root');
          return { addClass: 'material-icons', text: 'device_hub' };
        }
      }
      // activeVisible: true,
      // autoCollapse: true,
      // autoScroll: true,
      // clickFolderMode: 2
    });
  }

  destroyTree() {
    if (this.tree) {
      this.tree.destroy();
      this.tree = undefined;
    }
  }

  initializeVisualizationType() {
    this.VisualizationTypeEnum = {
      'thumb-grid': { id: 0, name: 'thumb-grid', selector: '.thumb-grid', propertyName: 'thumbGrid', buttonSelector: '.show-as-thumb-grid-button'},
      'datatable': { id: 1, name: 'datatable', selector: '.datatable', propertyName: 'datatable', buttonSelector: '.show-as-datatable-button'}
    };
    this.visualizationTypeDefault = this.VisualizationTypeEnum['datatable'];
    this.visualizationType = this.visualizationTypeDefault;
  }

  showCurrentVisualizationTypeElement() {
    _.values(this.VisualizationTypeEnum).forEach((type, index, array) => {
      const nextType = this.getNextVisualizationType();
      if(type.name === this.visualizationType.name) {
        $(type.selector).removeClass('dam-hidden');
        $(type.buttonSelector).addClass('dam-hidden');
        $(nextType.buttonSelector).removeClass('dam-hidden');
      } else {
        $(type.selector).addClass('dam-hidden');
      }
    });
  }

  getNextVisualizationType() {
    const nextIndex = (this.visualizationType.id + 1) % _.size(this.VisualizationTypeEnum);
    return _.find(this.VisualizationTypeEnum, { id: nextIndex });
  }

  setNextVisualizationType() {
    this.visualizationType = this.getNextVisualizationType();
    this.showCurrentVisualizationTypeElement();
  }

  initializeSidebar() {
    this.sidebar = new Sidebar();
    const node = this.tree.findNode(this.state.current_uuid);
    this.showSidebar(node.data);
  }

  // destroyUploader() {
  //   if (!this.uploader) { return; }
  //   this.uploader.destroy();
  //   this.uploader = undefined;
  // }

  // initializeUploader(node) {
  //   this.destroyUploader();
  //   this.uploader = new Uploader(
  //     '#assets-dropzone',
  //     {
  //       railsApi: {
  //         initUploadUrl: Routes.init_upload_admin_assets_path({resource_uuid: node.uuid}),
  //         authenticityToken: gon.authenticity_token
  //       }
  //     }
  //   );
  // }

  initializeMoveNodeModal() {
    this.moveNodeModal = new MoveNodeModal({
      bundle_id: this.state.bundle_id,
      onMove: (node, oldParent, newParent) => {
        this.handleNodeMoved(node, oldParent, newParent);
      }
    });
  }

  initializeNewChildNodeModal() {
    this.newChildNodeModal = new NewChildNodeModal({
      bundle_id: this.state.bundle_id,
      onCreate: (node, parent) => {
        this.handleChildNodeCreated(node, parent);
      }
    });
  }

  initializeAddResourcesModal() {
    this.addResourcesModal = new AddResourcesModal({
      bundle_id: this.state.bundle_id,
      onSubmit: (parent, nodes) => {
        this.handleAddedResources(parent, nodes);
      }
    });
  }

  initializeBlockingProgressModal() {
    this.blockingProgressModal = new BlockingProgressModal({
      // onSubmit: (parent, nodes) => {
      //   this.handleAddedResources(parent, nodes);
      // }
    });
  }

  destroyGroupToolbar() {
    if(this.groupToolbar) {
      this.groupToolbar.destroy();
      this.groupToolbar = undefined;
    }
  }

  initializeGroupToolbar() {
    this.destroyGroupToolbar();
    this.groupToolbar = new GroupToolbar(this);
    const node = this.tree.findNode(this.state.current_uuid).data;
    if(node.typology === 'root_typology') {
      this.groupToolbar.disableVisitParentButton();
    } else {
      this.groupToolbar.enableVisitParentButton();
    }
  }

  destroyDatatable() {
    if (this.datatable) {
      this.datatable.destroy();
      this.datatable = undefined;
    }
  }

  initializeDatatable() {
    this.destroyDatatable();
    this.datatable = new Datatable(this);
  }

  destroyThumbGrid() {
    if(this.thumbGrid) {
      this.thumbGrid.destroy();
      this.thumbGrid = undefined;
    }
  }

  initializeThumbGrid() {
    this.destroyThumbGrid();
    this.thumbGrid = new ThumbGrid(this);
  }

  collapseSideNav(){
    // admin-lte 3
    $('[data-widget="pushmenu"]').PushMenu('expand');
    $('[data-widget="pushmenu"]').PushMenu('collapse');
    // admin-lte 2
    // window.AdminLTE.pushMenu.expand();
    // window.AdminLTE.pushMenu.collapse();
  }

  removeBreadcrumbsBorder() {
    $('ol.breadcrumb').css('border-bottom', 'none');
  }

  removeFooter() {
    $('footer.main-footer').remove();
  }

  setContentHeight() {
    const headerHeight = $('header.main-header').height();
    const breadcrumbsHeight = 36;
    // .outerHeight and .heigh behave differently between firefox and chrome...
    // const browseHeaderHeight = $('.browse-container__header').outerHeight() + parseInt($('.browse-container__header').css('margin-top')) - 4;
    const browseHeaderHeight = 40;
    const footerHeight = $('footer.main-footer').outerHeight();
    const bodyHeight = $('body').height();
    let visibleContentHeight = (bodyHeight - headerHeight - breadcrumbsHeight - browseHeaderHeight);

    if(footerHeight) {
      visibleContentHeight -= footerHeight;
    }

    $('.content .browse-container').css({
      'height': visibleContentHeight + 'px',
      'maxHeight': visibleContentHeight + 'px',
    });
  }

  // ACTION initialization
  load() {
    this.state = Object.assign(INITIAL_STATE, {
      bundle_id: gon.bundle_id,
      current_uuid: gon.current_uuid,
      readOnly: gon.read_only
    });

    // Initialization
    this.enableVersionSwitch();
    this.initializeTree();
    this.initializeSidebar();
    this.initializeVisualizationType();
    this.initializeGroupToolbar();
    this.showCurrentVisualizationTypeElement();
    this.initializeDatatable();
    this.initializeThumbGrid();
    this.initializeMoveNodeModal();
    this.initializeNewChildNodeModal();
    this.initializeAddResourcesModal();
    this.initializeBlockingProgressModal();

    this.queryMetaSearch = new QueryMetaSearch(gon.autocompleter_list);

    eventsListener.initialize(this);

    // Operations
    this.tree.setCurrent(this.state.current_uuid);

    // View related operations
    // this.collapseSideNav(); // temporary disabled until we figure out admin lite v3 js
    this.removeBreadcrumbsBorder();
    this.removeFooter();
    this.setContentHeight();
    $(window).resize(() => {
      this.setContentHeight();
    });

    // TODO: remove
    window.browse = this;
  }

  disableSidebarEditing() {
    $('.browse-container').find('.editable-bundle-meta-datum').addClass('plain-text');
    $('.browse-container').find('.editable-bundle-meta-datum--general-title').addClass('plain-text');
    $('.browse-container').find('.Sidebar .metadata__actions').addClass('dam-hidden');
    $('.browse-container').find('.Sidebar .metadata__entries .datum__remove').addClass('dam-hidden');
  }

  enableVersionSwitch() {
    const $versionSelectElement =  $('body').find('.root-info__release-version select');
    $versionSelectElement.select2({
      minimumResultsForSearch: -1,
      templateResult: function (d) { return $(d.text); },
      templateSelection: function (d) { return $(d.text); }
    });
    $versionSelectElement.on('select2:select', e => {
      window.location.href = e.params.data.element.dataset['url'];
    });
  }

  destroyVersionSwitch() {
    $('body').find('#select2-release-versions-container').closest('span.select2.select2-container').remove();
  }

  destroy() {
    eventsListener.destroy(this);

    this.destroyTree();
    this.destroyGroupToolbar();
    this.destroyDatatable();
    this.destroyThumbGrid();
    this.destroyVersionSwitch();

    if (this.sidebar) {
      // this.closeSidebar();
      this.sidebar.destroy();
      this.sidebar = undefined;
    }

    if (this.moveNodeModal) {
      this.moveNodeModal.destroy();
      this.moveNodeModal = undefined;
    }

    if (this.newChildNodeModal) {
      this.newChildNodeModal.destroy();
      this.newChildNodeModal = undefined;
    }

    if (this.addResourcesModal) {
      this.addResourcesModal.destroy();
      this.addResourcesModal = undefined;
    }

    if (this.blockingProgressModal) {
      this.blockingProgressModal.destroy();
      this.blockingProgressModal = undefined;
    }

    if (this.queryMetaSearch) {
      this.queryMetaSearch.destroy();
      this.queryMetaSearch = undefined;
    }
  }
}

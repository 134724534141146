import Modal from '../Modal';
import { spinner } from '../Spinner';
import Select2 from '../Select2';
import { fetchNodesRequest, updateNodeParentRequest } from '../../requests/bundles';

export default class MoveNodeModal extends Modal {
  get moveFooterButtons() {
    return [
      {
        content: I18n.t('admin.actions.close'),
        onClick: 'close',
        className: 'btn btn-default'
      },
      {
        content: I18n.t('admin.actions.move'),
        onClick: (e) => {
          e.preventDefault();
          this.handleFormSubmit();
        },
        className: 'btn btn-primary',
        id: 'move-node-submit'
      }
    ];
  }

  get $form() {
    return this.$.find('#move-node-form');
  }

  get $submit() {
    return this.$.find('#move-node-submit');
  }

  get $input() {
    return this.$.find('#move-node-parent-uuid');
  }

  constructor(props = {}) {
    const { open, ...otherProps } = props;
    super({ open, domId: 'move-node-modal' });

    this.currentNode = null;
    this.bundle_id = otherProps.bundle_id;
    this.onMove = otherProps.onMove;
  }

  buildForm(nodes) {
    const options = nodes.map((node) => (
      `<option value="${node.uuid}">${node.name}</option>`
    ));

    return `
      <form novalidate="novalidate" accept-charset="UTF-8" id="move-node-form">
        <div class="form-group string required bundle_name">
          <label class="control-label string required" for="bundle_name">
            <abbr title="required">*</abbr> ${I18n.t('admin.nodes.new_parent')}
          </label>
          <select data-placeholder="Seleziona il nuovo nodo padre..." class="form-control required select" name="bundle[parent_uuid]" id="move-node-parent-uuid">
            <option></option>
            ${options.join()}
          </select>
        </div>
      </form>
    `;
  }

  destroySelect() {
    if (this.selectHandler) {
      Select2.destroy(this.$input);
      this.selectHandler = undefined;
    }
  }

  initializeSelect() {
    this.destroySelect();
    this.selectHandler = Select2.initByElement(this.$input, {
      allowClear: true,
      multiple: false
    });
  }

  initializeForm(nodes) {
    this.update({
      content: this.buildForm(nodes),
      footerButtons: this.moveFooterButtons
    });

    this.initializeSelect();

    this.$submit.prop('disabled', true);

    this.$input.on('change', (e) => {
      e.preventDefault();
      this.handleInputChange();
    });

    this.$form.on('submit', (e) => {
      e.preventDefault();
      this.handleFormSubmit();
    });
  }

  resetNode() {
    this.destroySelect();
    this.removeEventHandlers();
    this.currentNode = null;
  }

  loadNode(node) {
    if (node && node === this.currentNode) {
      this.open();
      return;
    }

    this.resetNode();
    this.currentNode = node;

    this.update({
      title: I18n.t('admin.nodes.move_child', { name: node.name }),
      content: spinner(),
      footerButtons: []
    });

    this.open();

    fetchNodesRequest(this.bundle_id, { exclude: node.uuid })
      .done((payload) => { this.initializeForm(payload.nodes); });
  }

  handleInputChange() {
    const value = this.$input.val();

    if (value && value.length > 0) {
      this.$submit.prop('disabled', false);
    } else {
      this.$submit.prop('disabled', true);
    }
  }

  handleFormSubmit() {
    this.$submit.prop('disabled', true);
    const parent_uuid = this.$input.val();
    this.updateContent(spinner());

    updateNodeParentRequest(this.bundle_id, this.currentNode.uuid, parent_uuid)
      .done((payload) => {
        if (this.onMove) {
          this.onMove(payload.node, payload.destination);
        }
      });
  }

  removeEventHandlers() {
    this.$form.off('submit');
    this.$submit.off('click');
    this.$input.off('change');
  }

  destroy() {
    this.resetNode();
    this.$.remove();
  }
}

export default class Base {
  load() {}

  requestStart() {}

  beforeCache() {}

  beforeRender() {}

  destroy() {}
}

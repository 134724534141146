/* eslint-disable */
/* global setResourceData */
var learningTypesMap;

function getbSmartId(learning_type, learningTypesString) {
  const NO_PLAYER = -1;

  try {
    learningTypesMap = JSON.parse(learningTypesString);
  } catch (e) {
    return NO_PLAYER;
  }

  // se non c'è un mapping ritorniamo la pagina 'Tipologia di risorsa
  // non ancora disponibile'
  if (learningTypesMap.hasOwnProperty(learning_type)) {
    return learningTypesMap[learning_type];
  }

  return NO_PLAYER;
}

function getAssetUse(filename, id) {
  switch (id) {
    case learningTypesMap['exercise']:
      if (filename.endsWith('.xml')) {
        return 'launch_file';
      }

      return null;
    case learningTypesMap['gallery']:
    case learningTypesMap['karaoke']:
      if (filename.endsWith('data.xml')) {
        return 'launch_file';
      }

      return null;
    case learningTypesMap['video_srt']:
      if (filename.endsWith('.mp4')) {
        return 'launch_file';
      }

      return null;

    case learningTypesMap['exercise_v2']:
      if (filename.endsWith('content.json')) {
        return 'launch_file';
      }

      return null;
    default:
      return 'launch_file';
  }
}

function getUrl(url, token) {
  const request = new XMLHttpRequest();

  return new Promise(function (resolve, reject) {
    request.onreadystatechange = function () {
      if (request.readyState !== 4) {
        return;
      }

      if (request.status === 200) {
        resolve(request.responseText);
      } else {
        reject(new Error(request.status));
      }
    };

    request.open('GET', url, true);

    if (token) {
      request.setRequestHeader('Authorization', 'Bearer ' + token);
    }

    request.send();
  });
}

function checkForUrl(rawResponse, successCB, failCB) {
  const assetObject = JSON.parse(rawResponse);

  if (assetObject.hasOwnProperty('url')) {
    successCB(assetObject.url);
  }

  failCB(new Error('Malformed Resource.'));
}

function needsContent(id) {
  switch (id) {
    case learningTypesMap['video_srt']:
    case learningTypesMap['link_web']:
    case learningTypesMap['browser']:
      return true;
    default:
      return false;
  }
}

function extractMetaData(metaDataArray, target) {
  if (!Array.isArray(metaDataArray)) {
    return null;
  }

  return metaDataArray.find(function (meta) {
    return meta.category_code === target;
  }
  );
}

function contentFromMeta(rawMeta) {
  try {
    return JSON.parse(rawMeta.value);
  } catch (e) {
    return null;
  }
}

function needsAssets(id) {
  switch (id) {
    case learningTypesMap['link_web']:
      return false;
    default:
      return true;
  }
}

function needsLocalPath(id) {
  switch (id) {
    case learningTypesMap['browser']:
    case learningTypesMap['scorm']:
    case learningTypesMap['exercise_scorm']:
      return true;
    default:
      return false;
  }
}

function getLocalPath(resource, proxyUrl, parsedContent, resType) {
  const pieces = [proxyUrl];
  const projectUuid = resource.project_uuid || 'np';

  // dobbiamo inserire un livello fittizio nella url, altrimenti
  // gli scorm (e i browser gallery di dea) non trovano le rispettive
  // librerie.
  const scormFakeLevel = Math.floor(Math.random() * (900) + 100);

  switch (resType) {
    case learningTypesMap['browser']:
      pieces.push(projectUuid);
      pieces.push(resource.uuid);
      pieces.push(scormFakeLevel);
      pieces.push(parsedContent.launch_file);

      break;

    case learningTypesMap['scorm']:
    case learningTypesMap['exercise_scorm']:
      pieces.push(projectUuid);
      pieces.push(resource.uuid);
      pieces.push(scormFakeLevel);

      break;
  }

  return pieces.join('/');
}

function getContentObject(id, assets) {
  return new Promise(function (resolve, reject) {
    const content = {};

    switch (id) {
      case learningTypesMap['video_srt']:
        assets.forEach(function (asset) {
          if (asset.original_path.endsWith('.mp4')) {
            content.video = asset.original_path;
          }

          if (asset.original_path.endsWith('.srt')) {
            content.srt = asset.original_path;
          }
        });

        if (!content.hasOwnProperty('video')
          || !content.hasOwnProperty('srt')) {
          reject(new Error('Malformed resource.'));
        }

        resolve(content);
        break;

      case learningTypesMap['link_web']:
        getUrl(assets[0].file_wrapper.url)
          .then(function (response) {
            return checkForUrl(response, resolve, reject);
          }).then(function (url) {
            content.url = url;

            resolve(content);
          });
        break;

      case learningTypesMap['browser']:
        // XXX per ora non abbiamo il metadato in caso di risorse create
        // manualmente, per cui ipotizziamo sia index.html.
        // A brevissimo verrà integrato.
        content.launch_file = "index.html";

        resolve(content);
        break;

      // no default
    }
  });
}

function getLearningType(metaDataArray) {
  const learningType = metaDataArray.find(function (meta) {
    return meta.category_code === 'educational_technical_resource_type';
  });

  if (learningType) {
    return learningType.value;
  }

  return 'not_found';
}

function isPdf(resource) {
  // usiamo lo stesso metodo che usa il player per capire se si tratta di un pdf
  // controlliamo che abbia un asset solo e di tipo pdf
  var assets = resource.assets;
  if (assets && assets.length === 1) {
    var asset = assets[0];
    if (asset.original_path.endsWith('.pdf')) {
      return true;
    }
  }

  return false;
}

export function getParsedRes(damRes, proxyUrl, learningTypes, authToken) {
  const learningType = damRes.resource_template.code;
  const bSmartId = getbSmartId(learningType, learningTypes);

  // in caso di risorsa external non possiamo sapere quando il download
  // è terminato, quindi mostriamo un div che chiede all'utente di chiudere
  // la finestra al termine del download (nascondendo il div del player)
  // che sarebbe altrimenti bianco.
  if (learningType === 'external' && !isPdf(damRes)) {
    document.getElementById("external-info-div").style.display = "block";
    document.getElementById("my-bsmart").style.display = "none";
  }

  const playerRes = {
    id: damRes.id,
    resource_code: damRes.uuid,
    resource_type_id: bSmartId,
    title: damRes.cached_name,
    assets: [],
    value: -1 // costante
  };

  const contentMeta = extractMetaData(damRes.meta_data, "content");

  const parsedContent = contentFromMeta(contentMeta);

  const addLocalPath = needsLocalPath(bSmartId);

  if (needsAssets(bSmartId)) {
    damRes.assets.forEach(function (asset) {
      const newAsset = {
        id: asset.id,
        filename: asset.original_path,
        use: getAssetUse(asset.original_path, bSmartId),
        md5: asset.content_md5,
        size: asset.content_length,
        url: asset.download_url,
        encrypted: false, // Gli asset del DAM non sono cifrati
        uploaded_at: asset.created_at
      };

      if (addLocalPath) {
        newAsset.local_path = getLocalPath(damRes, proxyUrl, parsedContent, bSmartId);
      }

      playerRes.assets.push(newAsset);
    });
  }



  Promise.resolve(addLocalPath)
    .then(
      function (queryProxy) {
        if (queryProxy) {
          const url = proxyUrl + '/api/v1/verify/resource/' + damRes.uuid;
          return getUrl(url, authToken);
        }

        return true;
      }
    )
    .then(function () {
      if (parsedContent) {
        playerRes.content = parsedContent;

        if (parsedContent.external_browser) {
          playerRes.content.external_browser = false;
        }
      } else if (needsContent(bSmartId)) {
        getContentObject(bSmartId, damRes.assets)
          .then(function (content) {
            playerRes.content = content;

            if (playerRes.content.external_browser) {
              playerRes.content.external_browser = false;
            }

            setResourceData(playerRes, null, null);
          });

        return;
      }

      setResourceData(playerRes, null, null);
    }).catch(function (error) {
      switch (error.message) {
        case "0":
        case "404":
          //qui restituiamo la pagina di cortesia in caso di proxy non
          // disponibile / risorsa non ancora scompattata
          // 0: errore generico (di solito proxy non raggiungibile)
          document.getElementById("my-bsmart").style.display = "none";
          document.getElementById("res-unavailable-div").style.display = "block";
          break;
        default:
          console.error(error);
          throw error;
      }
    })
}

export function checkProjectIsExtracted(proxyUrl, authToken, projectUuid) {
  const url = proxyUrl + '/api/v1/extracted/project/' + projectUuid;

  return getUrl(url, authToken)
    .then(function() {
      document.getElementById("proxy-ok").style.display = "table-row";
    })
    .catch(function(e) {
      document.getElementById("proxy-ok").style.display = "none";
    });
}

import Modal from '../Modal';
import { spinner } from '../Spinner';
import { newChildNodeFormRequest, createChildRequest } from '../../requests/bundles';

export default class NewChildNodeModal extends Modal {
  get moveFooterButtons() {
    return [
      {
        content: I18n.t('admin.actions.close'),
        onClick: 'close',
        className: 'btn btn-default'
      },
      {
        content: I18n.t('admin.actions.create'),
        onClick: (e) => {
          e.preventDefault();
          this.handleFormSubmit();
        },
        className: 'btn btn-primary',
        id: 'new-child-node-submit'
      }
    ];
  }

  get $form() {
    return this.$.find('#new-child-node-form');
  }

  get $submit() {
    return this.$.find('#new-child-node-submit');
  }

  get $input() {
    return this.$.find('#new-child-name-input');
  }

  constructor(props = {}) {
    const { open, ...otherProps } = props;
    super({ open, domId: 'new-child-node-modal' });

    this.currentNode = null;
    this.bundle_id = otherProps.bundle_id;
    this.onCreate = otherProps.onCreate;
  }

  initializeModal(payload) {
    this.update({
      content: payload,
      footerButtons: this.moveFooterButtons
    });

    this.$submit.prop('disabled', true);

    this.$input.on('keyup', () => {
      this.handleInputChange();
    });

    this.$form.on('submit', (e) => {
      e.preventDefault();
      this.handleFormSubmit();
    });
  }

  resetNode() {
    this.removeEventHandlers();
    this.currentNode = null;
  }

  // display the modal given the future parent node
  loadNode(node) {
    if (node && node === this.currentNode) {
      this.open();
      return;
    }

    this.resetNode();
    this.currentNode = node;

    this.update({
      title: I18n.t('admin.nodes.new_child', { name: node.name }),
      content: spinner(),
      footerButtons: []
    });

    this.open();

    newChildNodeFormRequest(this.bundle_id)
      .done((payload) => { this.initializeModal(payload); });
  }

  handleInputChange() {
    const value = this.$input.val();

    if (value && value.length > 0) {
      this.$submit.prop('disabled', false);
    } else {
      this.$submit.prop('disabled', true);
    }
  }

  handleFormSubmit() {
    this.$submit.prop('disabled', true);

    const parentUuid = this.currentNode.uuid;
    const data = `${this.$form.serialize()}&parent_uuid=${parentUuid}`;

    createChildRequest(this.bundle_id, data)
      .always(() => { this.$submit.prop('disabled', false); })
      .done((payload) => {
        if (payload.status === 'ok') {
          this.onCreate(payload.child, this.currentNode);
        } else {
          this.modal.updateContent(payload.content);
        }
      });
  }

  removeEventHandlers() {
    this.$form.off('submit');
    this.$submit.off('click');
    this.$input.off('change');
  }

  destroy() {
    this.removeEventHandlers();
    this.$.remove();
  }
}

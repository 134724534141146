import 'select2'

export default {
  defaultOptions: {
    theme: 'bootstrap',
    // width: '100%'
  },

  mergedOptions(options = {}) {
    return Object.assign(Object.assign({}, this.defaultOptions), options);
  },

  init(selector = 'select.select', options = {}) {
    return $(selector).select2(this.mergedOptions(options));
  },

  initByElement($el, options = {}) {
    return $el.select2(this.mergedOptions(options));
  },

  initByParent($parent, selector = 'select.select', options = {}) {
    const $children = $parent.find(selector);
    return this.initByElement($children, this.mergedOptions(options));
  },

  destroy($el) {
    try {
      return $el.select2('destroy');
    } catch (e) {
      return $el;
    }
  }
};

import classNames from 'classnames';

export const spinner = ({ className } = {}) => {
  return `
    <div class="${classNames('Spinner', className)}"></div>
  `;
};

export default class Spinner {
  render() {
    return spinner();
  }

  // TODO: implement some fade in/out state logic
  hide() {}
  show() {}
}

import ApplicationAction from '../../application';

const TOOLBAR_CONTAINER = '.group-toolbar';
const VISIT_PARENT_BUTTON = '.visit-parent-button';
const SHOW_AS_DATATABLE_BUTTON = '.show-as-datatable-button';
const SHOW_AS_THUMB_GRID_BUTTON = '.show-as-thumb-grid-button';
const MOVE_UP_BUTTON = '.move-up-button';
const MOVE_DOWN_BUTTON = '.move-down-button';
// const DESELECT_ALL_BUTTON = '.deselect-all-button';
const ENABLE_SELECTION_BUTTON = '.enable-selection-button';
const DISABLE_SELECTION_BUTTON = '.disable-selection-button';
const appAction = new ApplicationAction();


export default class GroupToolbar {
  constructor(browse) {
    this.browse = browse;
    this.currentTreeNode = this.browse.tree.findNode(this.browse.state.current_uuid);
    this.initializeButtonsEvents();

    // TODO: remove if Selection is available in ThumbGrid
    this.toggleSelectionButton();

    appAction.destroy();
    appAction.initializebSmartModal();
    appAction.initModalAnchors();
  }

  initializeButtonsEvents() {
    this.$toolbarContainer.find(this.$visitParentButton).on('click', this.visitParent.bind(this));
    this.$toolbarContainer.find(this.$visualizationButtons).on('click', this.handleVisualizationTypeButtonsClick.bind(this));
    this.$toolbarContainer.find(this.$enableSelectionButton).on('click', this.handleEnableSelectionButtonClick.bind(this));
    this.$toolbarContainer.find(this.$disableSelectionButton).on('click', this.handleDisableSelectionButtonClick.bind(this));
    this.$toolbarContainer.find(this.$moveDownButton).on('click', this.handleMoveButtonClick.bind(this, 'down'));
    this.$toolbarContainer.find(this.$moveUpButton).on('click', this.handleMoveButtonClick.bind(this, 'up'));
  }

  handleVisualizationTypeButtonsClick(){
    this.browse.setNextVisualizationType();
    this.browse[this.browse.visualizationType.propertyName].initializeTooltips();

    // TODO: remove if Selection is available in ThumbGrid
    this.toggleSelectionButton();
  }

  handleEnableSelectionButtonClick() {
    $(document).trigger('datatable:selection:enabled');
  }

  handleDisableSelectionButtonClick() {
    $(document).trigger('datatable:selection:disabled');
  }

  handleMoveButtonClick(direction) {
    this.browse.datatable.moveRow(direction);
  }

  get $defaultButtons() {
    return this.$visitParentButton;
  }

  // TODO: needs update, this selector does not consider readOnly state
  get $allButtons() {
    return this.$toolbarContainer.find('[class*="-button "]');
  }

  get $toolbarContainer() {
    return $(TOOLBAR_CONTAINER);
  }

  get $visitParentButton() {
    return $(VISIT_PARENT_BUTTON);
  }

  get $showAsListButton() {
    return $(SHOW_AS_DATATABLE_BUTTON);
  }

  get $showAsGridButton() {
    return $(SHOW_AS_THUMB_GRID_BUTTON);
  }

  get $visualizationButtons() {
    return this.$showAsGridButton.add(this.$showAsListButton);
  }

  get $moveButtons() {
    return this.$moveUpButton.add(this.$moveDownButton);
  }

  get $moveUpButton() {
    if(this.browse.state.readOnly) { return $(); }
    return $(MOVE_UP_BUTTON);
  }

  get $moveDownButton() {
    if(this.browse.state.readOnly) { return $(); }
    return $(MOVE_DOWN_BUTTON);
  }

  get $enableSelectionButton() {
    if(this.browse.state.readOnly) { return $(); }
    return $(ENABLE_SELECTION_BUTTON);
  }

  get $disableSelectionButton() {
    if(this.browse.state.readOnly) { return $(); }
    return $(DISABLE_SELECTION_BUTTON);
  }

  get $sortingActionsButtons() {
    return this.$enableSelectionButton;
  }

  get $zeroElementsActionsButtons() {
    return this.$disableSelectionButton;
  }

  get $singleElementActionsButtons() {
    return this.$moveButtons.add(this.$disableSelectionButton);
  }

  get $multipleElementsActionsButtons() {
    return this.$disableSelectionButton;
  }

  disableVisitParentButton() {
    this.$visitParentButton.prop('disabled', true);
  }

  enableVisitParentButton() {
    this.$visitParentButton.prop('disabled', false);
  }

  hideMoveButtons() {
    this.$moveButtons.addClass('dam-hidden');
  }

  showMoveButtons() {
    this.$moveButtons.removeClass('dam-hidden');
  }

  showSortingActionsButtons() {
    this.$sortingActionsButtons.removeClass('dam-hidden');
  }

  showZeroElementsActionsButtons() {
    this.$zeroElementsActionsButtons.removeClass('dam-hidden');
    this.$visualizationButtons.addClass('dam-hidden');
  }

  showSingleElementActionsButtons() {
    this.$singleElementActionsButtons.removeClass('dam-hidden');
    this.$visualizationButtons.addClass('dam-hidden');
  }

  showMultipleElementsActionsButtons() {
    this.$multipleElementsActionsButtons.removeClass('dam-hidden');
    this.$visualizationButtons.addClass('dam-hidden');
  }

  reset() {
    this.$allButtons.addClass('dam-hidden');
    this.$defaultButtons.removeClass('dam-hidden');
    this.browse.showCurrentVisualizationTypeElement();
  }

  // TODO: remove if Selection is available in ThumbGrid
  toggleSelectionButton() {
    if(this.browse.visualizationType.name == 'thumb-grid') {
      this.$enableSelectionButton.addClass('dam-hidden');
    } else {
      this.$enableSelectionButton.removeClass('dam-hidden');
    }
  }

  visitParent(){
    if(this.currentTreeNode.data.typology !== 'root_typology') {
      this.browse.visitNode(this.currentTreeNode.parent.data);
    }
  }

  destroy() {
    this.$toolbarContainer.find(this.$visitParentButton).off('click');
    this.$toolbarContainer.find(this.$visualizationButtons).off('click');
    this.$toolbarContainer.find(this.$enableSelectionButton).off('click');
    this.$toolbarContainer.find(this.$disableSelectionButton).off('click');
    this.$toolbarContainer.find(this.$moveDownButton).off('click');
    this.$toolbarContainer.find(this.$moveUpButton).off('click');
  }
}

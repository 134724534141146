import mySortable from 'sortablejs';
import _ from 'lodash';

import { arrangeNodesRequest } from '../../../requests/bundles';

const DRAG_HANDLE = '.drag-handle';

export default class Sortable {
  constructor(selector, bundle_id, current_uuid) {
    this.selector = selector;
    this.bundle_id = bundle_id;
    this.current_uuid = current_uuid;
    const container = document.querySelector(selector);

    this.initializeHoverStyle();

    if($(container).length){
      this.drake = mySortable.create(container, {
        animation: 200, // ms, animation speed moving items when sorting, `0` — without animation
        onStart: e => {
          e.target.classList.remove('use-hover');
        },
        onEnd: e => {
          // var item = e.item; // the current dragged HTMLElement
          e.target.classList.add('use-hover');
          this.handleDragend( );
        },
        handle: DRAG_HANDLE,
        ghostClass: 'sortable-ghost',  // Class name for the drop placeholder
        chosenClass: 'sortable-chosen' // Class name for the chosen item
      });
    }
  }

  get uuids() {
    const rows = Object.values($(this.selector).children('tr'));
    return _.compact(rows.map((e) => ($(e).data('uuid'))));
  }

  submitArrange() {
    arrangeNodesRequest(this.bundle_id, this.current_uuid, this.uuids).done((payload) => {
      // console.log('Arrangement done!', payload);
      $(document).trigger('tree:childNodes:sorted', [payload.node]);
    });
  }

  handleDragend() {
    this.submitArrange();
  }

  initializeHoverStyle() {
    $(DRAG_HANDLE).on('mouseenter', function() {
      const table_row = $(this).closest('.use-hover tr');
      $(table_row).addClass('datatable__row--handle-hover');
    });

    $(DRAG_HANDLE).on('mouseleave', function() {
      const table_row = $(this).closest('.use-hover tr');
      $(table_row).removeClass('datatable__row--handle-hover');
    });
  }

  destroy() {
    if(this.drake){
      this.drake.destroy();
      this.dragulaHandler = undefined;
    }
  }
}

import Base from '../base';
import Editables from '../../components/Editables';

export default class Edit extends Base {
  load() {
    new Editables(
      '.editable-bundle-meta-datum',
      {
        params: function(params) {
          //originally params contain pk, name and value
          params = {
            authenticity_token: gon.authenticity_token,
            values_attributes: [
              {
                id: this.dataset.valueId,
                [`value_${this.dataset.valueType}`]: params.value
              }
            ]
          };
          return params;
        }
      }
    );

    new Editables(
      '.editable-bundle-attribute',
      {
        params: function(params) {
          //originally params contain pk, name and value
          params = {
            authenticity_token: gon.authenticity_token,
            bundle: {
              id: params.id,
              [params.name]: params.value
            }
          };
          return params;
        }
      }
    );
  }

  destroy() {
    if (this.uploader) {
      this.uploader.destroy();
    }
  }
}

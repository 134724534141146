// import fancytree from 'jquery.fancytree';
import _ from 'lodash';
const fancytree = require('jquery.fancytree');
require('jquery.fancytree/dist/modules/jquery.fancytree.edit');
require('jquery.fancytree/dist/modules/jquery.fancytree.filter');
require('jquery.fancytree/dist/modules/jquery.fancytree.glyph');
require('vendor/fancytree/contextMenu.ext')

const DEFAULT_OPTIONS = {
  source: [],
  extensions: []
};

const FANCYTREE_PROP_KEYS = [
  'source', 'extensions', 'lazyLoad', 'click', 'dblclick'
];

export default class Treeview {
  constructor(selector, props = {}, otherOptions = {}) {
    this.props = props;
    this.selector = selector;

    this.initializeTree(otherOptions);
  }

  buildOptions(otherOptions = {}) {
    const options = Object.keys(this.props).reduce((map, key) => {
      if (_.includes(FANCYTREE_PROP_KEYS, key)) {
        map[key] = this.props[key];
      }
      return map;
    }, Object.assign({}, DEFAULT_OPTIONS));

    if (this.props.contextMenu) {
      if (!_.includes(options.extensions, 'contextMenu')) {
        options.extensions.push('contextMenu');
      }

      options.contextMenuOptions = {
        menu: this.props.contextMenu,
        actions: this.props.onContextMenu || (() => {})
      };
    }

    return {
      ...options,
      ...otherOptions
    };
  }

  initializeTree(otherOptions) {
    const options = this.buildOptions(otherOptions);
    $(this.selector).fancytree(options);
  }

  destroy() {
    $(this.selector).fancytree('destroy');
    // $(this.selector).html('');
  }

  get tree() {
    // return $(this.selector).fancytree('getTree');
    return $.ui.fancytree.getTree();
  }

  findNode(uuid) {
    return this.tree.findFirst((node) => (node.data.uuid == uuid));
  }

  removeNode(uuid) {
    const node = this.findNode(uuid);
    if (node) { node.remove(); }
  }

  reloadNode(uuid, cb = (n) => (n)) {
    const node = this.findNode(uuid);
    return node.load(true).done(() => (cb(node)));
  }

  activate(uuid) {
    const node = this.findNode(uuid);
    node.setFocus();
    node.setActive();
  }

  expand(uuid) {
    const node = this.findNode(uuid);
    node.setExpanded();
  }

  setCurrent(uuid) {
    const node = this.findNode(uuid);
    node.setFocus();
    node.setActive();
    node.setExpanded();
  }
}

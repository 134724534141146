import { ajaxHaders, handleAjaxFailure } from '../utils/ajax';

export const fetchBundleSubtree = ({ bundle_id, uuid }) => {
  return $.ajax({
    url: Routes.subtree_admin_bundle_path(bundle_id, uuid, { format: 'json' }),
    method: 'GET',
    dataType: 'json',
    headers: ajaxHaders()
  }).fail(handleAjaxFailure);
};

export const createChildRequest = (bundle_id, data) => {
  return $.ajax({
    url: Routes.create_child_admin_bundle_path(bundle_id, { format: 'json' }),
    data: data,
    method: 'POST',
    dataType: 'json',
    headers: ajaxHaders()
  }).fail(handleAjaxFailure);
};

export const duplicateRequest = (data) => {
  return $.ajax({
    url: Routes.duplicate_admin_bundles_path({ format: 'json' }),
    data: data,
    method: 'POST',
    dataType: 'json',
    headers: ajaxHaders()
  }).fail(handleAjaxFailure);
};

export const fetchNodeSidebar = (bundle_id, uuid) => {
  return $.ajax({
    url: Routes.node_info_admin_bundle_path(bundle_id, { uuid: uuid }),
    method: 'GET',
    dataType: 'html',
    headers: ajaxHaders()
  }).fail(handleAjaxFailure);
};

export const fetchNodeContent = (bundle_id, uuid) => {
  return $.ajax({
    url: Routes.node_content_admin_bundle_path(bundle_id, { uuid: uuid }),
    method: 'GET',
    dataType: 'html',
    headers: ajaxHaders()
  }).fail(handleAjaxFailure);
};

export const removeNodeRequest = (bundle_id, uuid) => {
  return $.ajax({
    url: Routes.remove_admin_bundle_path(bundle_id, { uuid: uuid }),
    method: 'POST',
    dataType: 'json',
    headers: ajaxHaders()
  }).fail(handleAjaxFailure);
};

export const fetchNodesRequest = (bundle_id, { exclude } = {}) => {
  return $.ajax({
    url: Routes.nodes_admin_bundle_path(bundle_id, { exclude: exclude, format: 'json' }),
    method: 'GET',
    dataType: 'json',
    headers: ajaxHaders()
  }).fail(handleAjaxFailure);
};

export const updateNodeParentRequest = (bundle_id, uuid, parent_uuid) => {
  return $.ajax({
    url: Routes.move_admin_bundle_path(bundle_id, uuid, { format: 'json' }),
    data: { parent_uuid: parent_uuid },
    method: 'POST',
    dataType: 'json',
    headers: ajaxHaders()
  }).fail(handleAjaxFailure);
};

export const newChildNodeFormRequest = (bundle_id) => {
  return $.ajax({
    url: Routes.new_child_admin_bundle_path(bundle_id, { format: 'html' }),
    method: 'GET',
    dataType: 'html',
    headers: ajaxHaders()
  }).fail(handleAjaxFailure);
};

// Reorder nodes siblings
export const arrangeNodesRequest = (bundle_id, parent_uuid, uuids) => {
  const url = Routes.arrange_admin_bundle_path(bundle_id, { format: 'json' });
  const data = {
    parent_uuid,
    'uuids[]': uuids // sorted UUIDs
  };

  return $.ajax({
    url,
    data,
    method: 'POST',
    dataType: 'json',
    headers: ajaxHaders()
  }).fail(handleAjaxFailure);
};

export const browseNodeRequest = (bundle_id, uuid = null) => {
  return $.ajax({
    url: Routes.browse_admin_bundle_path(bundle_id, { uuid: uuid }),
    method: 'GET',
    dataType: 'html',
    headers: ajaxHaders()
  }).fail(handleAjaxFailure);
};

// Reorder nodes siblings
export const addResourcesRequest = (bundle_id, parent_uuid, uuids) => {
  const url = Routes.add_resources_admin_bundle_path(bundle_id, { format: 'json' });
  const data = {
    parent_uuid,
    'uuids[]': uuids
  };

  return $.ajax({
    url,
    data,
    method: 'POST',
    dataType: 'json',
    headers: ajaxHaders()
  }).fail(handleAjaxFailure);
};

export const createPopulatedResourceRequest = (mode, resource_template_id, asset_ids) => {
  return $.ajax({
    url: Routes.populate_admin_resources_path(),
    data: {
      mode: mode,
      resource_template_id: resource_template_id,
      asset_ids: asset_ids
    },
    method: 'POST',
    headers: ajaxHaders()
  }).fail(handleAjaxFailure);
};

import Autocompleter from '../Autocompleter';

export default class QueryMetaSearch {
  constructor(autocompleter_list) {
    this.autocompleter = new Autocompleter(
      '#meta_search_form_query_string',
      {
        list: autocompleter_list,
        // We limit matches to parameterized strings.
        regexpBeforeCursor: /[a-z\-_:]*$/,
        regexpAfterCursor: /^[a-z\-_:]*/
      }
    );
  }

  destroy() {
    if (this.autocompleter) {
      this.autocompleter.destroy();
      this.autocompleter = undefined;
    }
  }
}

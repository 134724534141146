import * as d3 from 'd3';

// Generate a tree given the ltree paths list
export const stratifyPaths = (data, pathKey = 'id') => {
  const stratify = d3.stratify().parentId((d) => (
    d[pathKey].substring(0, d[pathKey].lastIndexOf('.'))
  ));

  return stratify(data).sort((a, b) => (
    (a.height - b.height) || a[pathKey].localeCompare(b[pathKey])
  ));
};

// Creates a curved (diagonal) path from parent to the child nodes
export const diagonal = (s, d) => (
  `M ${s.y} ${s.x}
   C ${(s.y + d.y) / 2} ${s.x},
     ${(s.y + d.y) / 2} ${d.x},
     ${d.y} ${d.x}`
);

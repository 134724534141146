import Modal from '../Modal';
import { showJob } from '../../requests/jobs';
import I18n from 'i18n-js/index.js.erb';

const DELAY = 500; // ms

export default class BlockingProgressModal extends Modal {
  constructor(props = {}) {
    const { open, ...otherProps } = props;
    super({ open, domId: 'blocking-progress-modal',
      keyboard: false, // Can't be closed with ESC
      backdrop: 'static' // Can't be closed by clicking backdrop
    });
    this.init();
  }

  init() {
    this.update({
      title: I18n.t('in_progress'),
      content: '',
      footerButtons: []
    });
    $('#blocking-progress-modal .modal-header .close').hide();
    $('#blocking-progress-modal .modal-footer').hide();
    // NOTE: animation and polling should be synced
    $('#blocking-progress-modal .progress-bar').css('transition-duration', DELAY/1000);
  }

  watch(jid) {
    $('#blocking-progress-modal .modal-body').load(Routes.admin_job_path(jid, { format: 'html' }));
    this.open();
    return new Promise ( function(resolve, reject){
      this.pollCycle(jid, resolve, reject);
    }.bind(this));
  }

  setProgressBar(completion){
    $('#blocking-progress-modal .progress-bar').
      css('width', completion + '%').
      attr('aria-valuenow', completion).
      text(completion + '%');
  }

  pollCycle(jid, resolve, reject) {
    showJob(jid).done(function(result){
      this.setProgressBar(result['pct_complete']);
      switch(result['status']) {
      case 'complete':
        resolve(result);
        setTimeout(function(){ this.close(); }.bind(this), DELAY);
        break;
      case 'failure':
        reject(result);
        break;
      default:
        setTimeout(function(){ this.pollCycle(jid, resolve, reject); }.bind(this), DELAY);
      }
    }.bind(this));
  }

  destroy() {
    this.$.remove();
  }
}

import { csrfToken } from './csrf';

export const ajaxHaders = (headers = {}) => ({
  'X-CSRF-Token': csrfToken(),
  ...headers
});

export const handleAjaxFailure = (e) => {
  console.error(e.responseText); // eslint-disable-line
};

import 'bootstrap/js/src/modal'

const template = `
<div class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Modal title</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <p>Content goes here</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
`;

const defaultProps = {
  title: null,
  domId: 'main-modal',
  open: false,
  buttons: [],
  footerButtons: null,
  emptyOnClose: false,
  style: null
};


const defaultButtonProps = {
  content: null,
  className: 'btn btn-default',
  onClick: () => {} // Function | 'close'
};

export default class Modal {
  constructor(props = {}) {
    const { domId, title, footerButtons, open, emptyOnClose, style, ...bootstrapModalProperties } = { ...defaultProps, ...props };
    const elementId = `#${domId}`;

    if ($(elementId).length) {
      this.$ = this.$Modal = $(elementId);
      this.state = { open };
      this.render();
    } else {
      this.emptyOnClose = emptyOnClose;
      this.footerButtons = [];
      this.$Container.append($(template).prop('id', domId));
      this.$ = $(elementId);
      this.$Modal = this.$.modal(bootstrapModalProperties);
      this.$Container.append(this.$Modal);
      this.updateTitle(title || '');
      this.updateFooterButtons(footerButtons || []);

      if (style) {
        this.styleModal(style);
      }

      if (this.emptyOnClose) {
        const contentRef = this.$Content;
        this.$Modal.on('hidden.bs.modal', function () {
          contentRef.html('');
        });
      }

      this.state = { open };
      this.render();
    }
  }

  get $Container() {
    return $('body');
  }

  get $Content() {
    return this.$Modal.find('.modal-body');
  }

  get $Title() {
    return this.$Modal.find('.modal-title');
  }

  get $Footer() {
    return this.$Modal.find('.modal-footer');
  }

  styleModal(styleName) {
    this.$Modal.addClass(styleName);
  }

  elementExists(){
    return this.$.length > 0;
  }

  destroy() {
    this.$.remove();
  }

  handleCloseButtonClick(e) {
    e.preventDefault();
    this.close();
  }

  open() {
    this.state.open = true;
    this.render();
  }

  close() {
    this.state.open = false;
    this.render();
  }

  handleClose() {
    this.$Modal.modal('hide');
  }

  handleOpen() {
    this.$Modal.modal('show');
  }

  render() {
    if (this.state.open) {
      this.handleOpen();
    } else {
      this.handleClose();
    }
  }

  updateTitle(title) {
    this.$Title.html(title);
  }

  updateContent(html) {
    this.$Content.html(html);
  }

  destroyFooterButton($button) {
    $button.off('click');
    $button.remove();
  }

  destroyFooterButtons() {
    this.footerButtons.forEach(($b) => (this.destroyFooterButton($b)));
    this.footerButtons = [];
    this.$Footer.html('');
  }

  addFooterButton({ content, className, onClick, id } = defaultButtonProps) {
    const $button = $('<button />');
    $button.html(content).addClass(className);
    if (id) { $button.prop('id', id); }
    $button.on('click', (e) => {
      if (onClick === 'close') { return this.close(); }
      onClick(e);
    });
    this.footerButtons.push($button);
    this.$Footer.append($button);
  }

  updateFooterButtons(buttons) {
    this.destroyFooterButtons();
    buttons.forEach((b) => (this.addFooterButton(b)));
  }

  update({ title, content, footerButtons } = {}) {
    if (title) { this.updateTitle(title); }
    if (content) { this.updateContent(content); }
    if (footerButtons) { this.updateFooterButtons(footerButtons); }
  }
}

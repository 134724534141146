const template = `
  <div class="Sidebar Sidebar--active">
    <div class="Sidebar__backdrop"/>
    <div class="Sidebar__container">
      <div class="Sidebar__panel">
        <a href="#close" class="Sidebar__close">&times;</a>
        <div class="Sidebar__content" />
      </div>
    </div>
  </div>
`;

const defaultProps = {
  open: false
};

export default class Sidebar {
  constructor({ open } = defaultProps) {
    this.$Container = $('.browse-container__three-panes');
    // this.$Sidebar = $(template);
    this.$Sidebar = this.$Container.find('.Sidebar');
    // this.$Container.append(this.$Sidebar);
    this.$ = this.$Sidebar;
    // this.$CloseButton = this.$Sidebar.find('.Sidebar__close');
    // this.$Backdrop = this.$Sidebar.find('.Sidebar__backdrop');
    // this.$CloseButton.on('click', this.handleCloseButtonClick.bind(this));
    // this.$Backdrop.on('click', this.handleCloseButtonClick.bind(this));
    this.$Content = this.$Sidebar.find('.Sidebar__content');
    // this.state = { open: open };
    // this.render();
  }

  destroy() {
    // this.$CloseButton.off('click');
    // this.$Backdrop.off('click');
    this.$Sidebar.remove();

    this.$Container = undefined;
    this.$Sidebar = undefined;
    // this.$CloseButton = undefined;
    this.$Content = undefined;
  }

  // handleCloseButtonClick(e) {
  //   e.preventDefault();
  //   this.close();
  // }

  // open() {
  //   this.state.open = true;
  //   this.render();
  // }

  // close() {
  //   this.state.open = false;
  //   this.render();
  // }

  // handleClose() {
  //   this.$Sidebar.removeClass('Sidebar--active');
  // }

  // handleOpen() {
  //   this.$Sidebar.addClass('Sidebar--active');
  // }

  // render() {
  //   if (this.state.open) {
  //     this.handleOpen();
  //   } else {
  //     this.handleClose();
  //   }
  // }

  findBody() {
    return this.$Content.find('.Sidebar__body');
  }

  findHeader() {
    return this.$Content.find('.Sidebar__header');
  }

  findFooter() {
    return this.$Content.find('.Sidebar__footer');
  }

  // adjustComponentsSize() {
  //   const $body = this.findBody();
  //   const $header = this.findHeader();
  //   const $footer = this.findFooter();
  //   const $tabs = $footer.find('.nav-tabs > li');

  //   if ($body.length === 0) { return; }

  //   $body.css({
  //     top: $header.length === 1 ? $header.outerHeight() : 0,
  //     bottom: $footer.length === 1 ? $footer.outerHeight() : 0
  //   });

  //   if ($tabs.length === 0) { return; }

  //   $tabs.css({
  //     width: `${100.0 / $tabs.length}%`
  //   });
  // }

  updateContent(html) {
    this.findBody().html(html);
    // this.adjustComponentsSize();
  }
}

import { ajaxHaders, handleAjaxFailure } from '../utils/ajax';

const defaultParams = (params) => (Object.assign({ format: 'json' }, params));

export const showJob = (jid, params = {}) => {
  return $.ajax({
    url: Routes.admin_job_path(jid, defaultParams(params)),
    method: 'GET',
    dataType: 'json',
    headers: ajaxHaders()
  }).fail(handleAjaxFailure);
};

import Base from '../base';

// import Selectables from '../../components/Selectables';
// import ContextMenu from '../../components/ContextMenu';

import DataTable from '../../components/DataTable';
import { createPopulatedResourceRequest } from '../../requests/bundles';
import { bulkDeleteRequest } from '../../requests/assets';

let selectedAssets = [];

export default class Index extends Base {
  // initContextMenu() {
  //   var generateTemplatesSubItems = function (prefix) {
  //     var items = {};
  //     $.each(gon.resource_templates, function(index, value) {
  //       items[[prefix, value.id].join(' ')] = { name: value.name };
  //     });
  //     items[[prefix, 'sep'].join(' ')] = '---------';
  //     items[[prefix, null].join(' ')] = { name: 'Generica' }; // TODO: i18n
  //     return items;
  //   };
  //
  //   var generateTemplatesItems = function () {
  //     return {
  //       'one-for-each templates': {
  //         'name': 'Crea una risorsa con ogni asset', // TODO: i18n
  //         'items': generateTemplatesSubItems('one-for-each')
  //       },
  //       'one-with-all templates': {
  //         'name': 'Crea una risorsa con tutti gli asset', // TODO: i18n
  //         'items': generateTemplatesSubItems('one-with-all')
  //       }
  //     };
  //   };
  //
  //   if (this.contextMenu) {
  //     this.contextMenu.destroy();
  //   }
  //
  //   window.contextMenu = new ContextMenu({
  //     selector: '.ui-selected',
  //     items: generateTemplatesItems(),
  //     callback: function(key, opt) {
  //       var mode, resource_template_id;
  //       [mode, resource_template_id] = key.split(' ');
  //       var asset_ids = $('.ui-selected').map(function(){ return $(this).data('asset-id'); }).toArray();
  //       createPopulatedResourceRequest(mode, resource_template_id, asset_ids)
  //         .done((payload) => {
  //           // console.log(payload);
  //         });
  //     }
  //   });
  // }

  get $searchForm() {
    return $('#asset_search');
  }

  load() {
    this.$searchForm.on('submit', this.handleSearch.bind(this));

    this.datatable = new DataTable('#assets-datatable', {
      selectedIds: selectedAssets,
      toolbar: {
        selector: '#assets-datatable-toolbar',
        actions: [
          {
            label: 'Crea singola risorsa da selezione',
            action: this.handleSingleResourceCreation.bind(this),
            collection: gon.resource_templates
          },
          {
            label: 'Crea più risorse da selezione',
            action: this.handleMultipleResourcesCreation.bind(this),
            collection: gon.resource_templates
          },
          {
            label: 'Deseleziona tutto',
            action: this.clearSelection.bind(this),
          }
          // {
          //   label: '<i class="fa fa-trash fa-fw"></i> Elimina selezione',
          //   action: this.handleBulkDelete.bind(this),
          //   class: 'btn btn-danger'
          // }
        ]
      }
    });

    this.datatable.on('DataTable:Selection', this.handleSelection);

    // this.selectables = new Selectables(
    //   '#ui-selectable-container',
    //   {
    //     filter: '.ui-selectable',
    //     cancel: '.btn'
    //   }
    // );
    //
    // this.initContextMenu();
    //
    // $(document).click(function(event) {
    //   /* NOTE: keep selection when clicking on context menus, modals and the container. */
    //   if($(event.target).closest('[class^=context-menu]').length) { return; }
    //   if($(event.target).closest('[class^=modal]').length) { return; }
    //   if(!$(event.target).closest('tbody').length) {
    //     $('.ui-selected').removeClass('ui-selected');
    //   }
    // });
  }

  destroy() {
    // this.selectables.destroy();
    // $.contextMenu('destroy');

    this.$searchForm.off('submit');

    if (this.datatable) {
      this.datatable.off('DataTable:Selection', this.handleSelection);
      this.datatable.destroy();
      this.datatable = undefined;
    }
  }

  handleSearch(e) {
    e.preventDefault();
    const url = [Routes.admin_assets_path(), this.$searchForm.serialize()].join('?');
    Turbolinks.visit(url);
  }

  handleSelection(e, selectedIds) {
    // Store selected assets into a global state
    selectedAssets = selectedIds;
  }

  handleSingleResourceCreation($el) {
    createPopulatedResourceRequest('one-with-all', parseInt($el.val()), this.datatable.selectedIds);
  }

  handleMultipleResourcesCreation($el) {
    createPopulatedResourceRequest('one-for-each', parseInt($el.val()), this.datatable.selectedIds);
  }

  handleBulkDelete() {
    if (!confirm(I18n.t('admin.general.are_you_sure'))) { return; }
    selectedAssets = [];
    bulkDeleteRequest(this.datatable.selectedIds, { format: 'json' }).done(() => {
      // This simulates an hard reload with Turbolinks
      Turbolinks.clearCache()
      Turbolinks.visit(window.location.toString())
    });
  }

  clearSelection() {
    this.datatable.clearSelection();
    selectedAssets = [];
  }
}

import actions from './actions/index';
import BaseAction from './actions/base';
import ApplicationAction from './actions/application';

// Wide app actions
const appAction = new ApplicationAction;
// Specific context actions
let contextAction = null;

const getCurrentAction = () => {
  const key = `${gon.controller_name}#${gon.action_name}`;
  return actions[key] || BaseAction;
};

const handleActionEvent = (eventName) => (e) => { // eslint-disable-line
  // console.log('turbolinks event =>', e);
  if(appAction && appAction[eventName]) { appAction[eventName](); }
  if(contextAction && contextAction[eventName]) { contextAction[eventName](); }
};

// Handle turbolinks events
const boot = () => {
  // Performe action initialization
  $(document).on('turbolinks:load', (e) => { // eslint-disable-line
    // console.log('turbolinks load =>', e);
    // Performe destroy action lifecycle
    appAction.destroy();
    if (contextAction) { contextAction.destroy(); }

    // Initialize a new action
    const CurrentAction = getCurrentAction();
    contextAction = new CurrentAction;
    appAction.load();
    contextAction.load();
  });

  $(document).on('turbolinks:request-start', handleActionEvent('destroy'));
  $(document).on('turbolinks:request-start', handleActionEvent('requestStart'));

  $(document).on('turbolinks:before-cache', handleActionEvent('destroy'));
  $(document).on('turbolinks:before-cache', handleActionEvent('beforeCache'));

  $(document).on('turbolinks:before-render', handleActionEvent('beforeRender'));

  $(document).on('turbolinks:click', handleActionEvent('click'));
};

export default boot;

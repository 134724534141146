function isInSubtreeOf(source, parentTarget) {
  if(source.uuid == parentTarget.data.uuid) { return true; }
  if(parentTarget.parent) { return isInSubtreeOf(source, parentTarget.parent); }
  return false;
}

const CLIPBOARD_STORAGE_ITEM = 'dam.clipboard';

const makeContextMenu = (browse) => (node) => { // eslint-disable-line
  const hasParent = !!node.parent.parent;
  const isRootTypology = node.data.typology === 'root_typology';
  const isGroupTypology = node.data.typology === 'group_typology';
  const isWrapperTypology = node.data.typology === 'wrapper_typology';

  const clipboardNode = JSON.parse(localStorage.getItem(CLIPBOARD_STORAGE_ITEM));

  return {
    // info: {
    //   icon: 'fa-info',
    //   name: I18n.t('admin.nodes.context_menu.more_info'),
    // },
    move_node: {
      icon: 'fa-share',
      name: I18n.t('admin.nodes.context_menu.move_child'),
      disabled: isRootTypology || browse.state.readOnly
    },
    new_child: {
      icon: 'fa-plus',
      name: I18n.t('admin.nodes.context_menu.new_child'),
      disabled: !(isGroupTypology || isRootTypology) || browse.state.readOnly
    },
    new_sibling: {
      icon: 'fa-plus',
      name: I18n.t('admin.nodes.context_menu.new_sibling'),
      disabled: isRootTypology || browse.state.readOnly
    },
    duplicate: {
      icon: 'fa-plus',
      name: I18n.t('admin.nodes.context_menu.duplicate'),
      disabled: isRootTypology || browse.state.readOnly
    },
    clipboard_copy: {
      icon: 'fa-clone',
      name: I18n.t('admin.nodes.context_menu.clipboard_copy'),
      disabled: isRootTypology
    },
    clipboard_paste: {
      icon: 'fa-clipboard',
      name: I18n.t('admin.nodes.context_menu.clipboard_paste'),
      disabled: isWrapperTypology || (clipboardNode === null) || isInSubtreeOf(clipboardNode, node)
    },
    add_resources: {
      icon: 'fa-cubes',
      name: I18n.t('admin.nodes.context_menu.add_resources'),
      disabled: !(isGroupTypology || isRootTypology) || browse.state.readOnly
    },
    sep1: '---------',
    remove_node: {
      icon: 'fa-trash',
      className: 'context-menu-danger',
      name: hasParent
        ? I18n.t('admin.nodes.context_menu.remove_node')
        : I18n.t('admin.nodes.context_menu.remove_tree'),
      disabled: browse.state.readOnly
    }
  };
};

export default makeContextMenu;

import I18n from 'i18n-js/index.js.erb';
import AutosizeInput from 'autosize-input';
import 'bootstrap'
import "vendor/x-editable-bs4/js/bootstrap-editable";

// $.fn.editable.defaults.mode = 'inline';
// Default request method is POST, you can change it via defaults config:
$.fn.editable.defaults.ajaxOptions = {type: 'PATCH'};

// Of course there are font paths problems; we circumvent
// them by replacing Glyphicons w/ FA in template.
$.fn.editableform.buttons = `
  <button type="submit" class="btn btn-primary btn-sm editable-submit">
    <i class="fa fa-check fa-fw"></i></button>
  <button type="button" class="btn btn-default btn-sm editable-cancel">
    <i class="fa fa-times fa-fw"></i></button>`;


const defaultOptions = {
  container: 'body',
  placeholder: I18n.t('Editables.placeholder', { locale: $('html').attr('lang') }),
  emptytext: I18n.t('Editables.emptytext', { locale: $('html').attr('lang') })
};

export default class Editables {
  constructor(selector, options) {
    $(selector).
      editable(Object.assign(Object.assign({}, defaultOptions), options)).
      on('shown', function(e, editable){

        var data;
        switch(editable.options.valueType) {
        case 'string':
          AutosizeInput(editable.input.$input[0], { minWidth: true });
          break;
        case 'boolean':
          // NOTE: this should NOT be necessary but xeditables is acting weird
          editable.input.$input.val(editable.value.toString()).trigger('change');
          break;
        case 'string_array':
          if(editable.isEmpty){
            data = [];
          } else if(Array.isArray(editable.value)){
            // not first edit
            data = editable.value;
          } else {
            // first edit
            data = editable.value.split(',');
          }
          editable.input.$input.select2({
            width: 'resolve',
            dropdownAutoWidth : true,
            data: data.map((v)=>({ id: v, text: v, selected: true })),
            tags: true,
            multiple: true,
            theme: 'bootstrap4',
            tokenSeparators: [',']
          });
          break;
        default:
          // NOOP
        }
      });
  }
}

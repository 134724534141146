import Base from '../base';
import Uploader from '../../components/Uploader';
import Editables from '../../components/Editables';

export default class Edit extends Base {
  load() {
    if(document.getElementById('assets-dropzone')) {
      this.uploader = new Uploader(
        '#assets-dropzone',
        {
          railsApi: {
            initUploadUrl: Routes.init_upload_admin_assets_path({erratum_uuid: gon.erratum_uuid}),
            authenticityToken: gon.authenticity_token
          }
        }
      );
    }

    new Editables(
      '.editable-virtual-asset-content',
      {
        params: function(params) {
          //originally params contain pk, name and value
          params = {
            authenticity_token: gon.authenticity_token,
            patch: {
              [params.name]: params.value
            }
          };
          return params;
        }
      }
    );
  }

  destroy() {
    if (this.uploader) {
      this.uploader.destroy();
    }
  }
}

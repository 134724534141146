import Base from '../base';
import Editables from '../../components/Editables';

export default class Edit extends Base {
  load() {
    new Editables(
      '.editable-channel-attribute',
      {
        params: function(params) {
          //originally params contain pk, name and value
          params = {
            authenticity_token: gon.authenticity_token,
            channel: {
              id: params.id,
              [params.name]: params.value
            }
          };
          return params;
        }
      }
    );

    // Simple trick to have a one-click file submission form.
    $('#import_file').hide();
    $('form.import > button').on('click', function(){ $('#import_file').click(); });
    $('#import_file').on('change', function(){ $('form.import').submit(); });
  }

  destroy() {
    if (this.uploader) {
      this.uploader.destroy();
    }
  }
}

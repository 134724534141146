import { ajaxHaders, handleAjaxFailure } from '../utils/ajax';

const defaultParams = (params) => (Object.assign({ format: 'json' }, params));

export const searchModalResources = (params = {}) => {
  return $.ajax({
    url: Routes.search_modal_admin_resources_path(defaultParams(params)),
    method: 'GET',
    dataType: params.format ? params.format : 'json',
    headers: ajaxHaders()
  }).fail(handleAjaxFailure);
};

export const addToGroup = (resource_id, group_uuid, params = {}) => {
  const url = Routes.perform_add_group_admin_resource_path(resource_id, defaultParams(params));
  const data = {
    resources_add_group_form: { uuid: group_uuid }
  };

  return $.ajax({
    url,
    data,
    method: 'POST',
    dataType: params.format ? params.format : 'json',
    headers: ajaxHaders()
  }).fail(handleAjaxFailure);
};

import Base from '../base';

import { showJob } from '../../requests/jobs';

export default class Index extends Base {
  load() {
    var urlParams = new URLSearchParams(window.location.search);
    const jid = urlParams.get('jid');
    const polling = urlParams.get('polling');

    if (jid != null && polling == "true") {
      this.poll(jid);
    }
  }

  poll(jid) {
    const that = this;
    showJob(jid).done(function(result){
      var completion = result['pct_complete'];

      $('.progress-bar').
        css('width', completion + '%').
        attr('aria-valuenow', completion).
        text(completion + '%');

      if (['complete', 'failed', 'interrupted'].includes(result['status'])) {
        Turbolinks.visit(location.pathname+"?jid="+jid);
      } else {
        setTimeout(function() { that.poll(jid); }, 500);
      }
    });
  }

  destroy() {
  }
}

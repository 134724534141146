import Base from '../base';
import Uploader from '../../components/Uploader';
import Editables from '../../components/Editables';

export default class Edit extends Base {
  load() {
    if(document.getElementById('assets-dropzone')) {
      this.uploader = new Uploader(
        '#assets-dropzone',
        {
          railsApi: {
            initUploadUrl: Routes.init_upload_admin_assets_path({resource_uuid: gon.resource_uuid}),
            authenticityToken: gon.authenticity_token
          }
        }
      );
    }

    new Editables(
      '.editable-bundle-meta-datum',
      {
        params: function(params) {
          //originally params contain pk, name and value
          params = {
            authenticity_token: gon.authenticity_token,
            values_attributes: [
              {
                id: this.dataset.valueId,
                [`value_${this.dataset.valueType}`]: params.value
              }
            ]
          };
          return params;
        }
      }
    );

    // TODO: this is kind of hackish - refactor?
    new Editables(
      '.editable-bundle-meta-datum--general-title',
      {
        params: function(params) {
          //originally params contain pk, name and value
          params = {
            authenticity_token: gon.authenticity_token,
            values_attributes: [
              {
                id: this.dataset.valueId,
                [`value_${this.dataset.valueType}`]: params.value
              }
            ]
          };
          return params;
        },
        success: (response, newValue) => {
          $('.editable-bundle-general-title').text(newValue);
        }
        // mode: 'inline'
      }
    );

    new Editables(
      '.editable-resource-attribute',
      {
        params: function(params) {
          //originally params contain pk, name and value
          params = {
            authenticity_token: gon.authenticity_token,
            resource: {
              id: params.id,
              [params.name]: params.value
            }
          };
          return params;
        }
      }
    );

    new Editables(
      '.editable-virtual-asset-content',
      {
        params: function(params) {
          //originally params contain pk, name and value
          params = {
            authenticity_token: gon.authenticity_token,
            patch: {
              [params.name]: params.value
            }
          };
          return params;
        }
      }
    );
  }

  destroy() {
    if (this.uploader) {
      this.uploader.destroy();
    }
  }
}

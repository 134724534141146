import Base from '../base';
import QueryMetaSearch from '../../components/QueryMetaSearch';

export default class Search extends Base {
  load() {
    this.queryMetaSearch = new QueryMetaSearch(gon.autocompleter_list);
  }

  destroy() {
    if (this.queryMetaSearch) {
      this.queryMetaSearch.destroy();
      this.queryMetaSearch = undefined;
    }
  }
}

import { hexToBase64 } from './hasher';
import { inferType } from './typer';

// Start an upload on Rails' side.
// Expected answer include put url signed with given headers.
export const initUpload = (file) => {
  return new Promise ( function (resolve, reject) {
    $.ajax({
      url: file.railsApi.initUploadUrl,
      type: 'post',
      dataType: 'json',
      data: {
        authenticity_token: file.railsApi.authenticityToken,
        signature_headers: file.signatureHeaders,
        file_path: file.fullPath,
        file_name: file.name
      },
      success: function(data, status, xhr){
        file.initUploadRequest = xhr;
        file.presignedPutUrl = xhr.responseJSON.data.presignedPutUrl;
        resolve(file);
      },
      error: function(xhr, status, error){
        file.initUploadRequest = xhr;
        reject(file);
      }
    });
  });
};

// Attach signature headers to file object.
export const prepHeaders = (file) => {
  return new Promise ( (resolve) => {
    file.signatureHeaders = {
      'Content-Length': file.size,
      'Content-MD5': hexToBase64(file.hash),
      'Content-Type': inferType(file)
    };
    resolve(file);
  });
};

// Stop an upload on Rails' side.
// We refer to the file through its MD5.
export const stopUpload = (file) => {
  return new Promise ( function (resolve, reject) {
    if (!file.railsApi.stopUploadUrl) {
      resolve(file);
    } else {
      $.ajax({
        url: file.railsApi.stopUploadUrl,
        type: 'post',
        dataType: 'json',
        data: {
          authenticity_token: file.railsApi.authenticityToken,
          hash: file.hash
        },
        success: function(data, status, xhr){
          file.stopUploadRequest = xhr;
          resolve(file);
        },
        error: function(xhr, status, error){
          file.stopUploadRequest = xhr;
          reject(file);
        }
      });
    }
  });
};


import Sortable from './Sortable';
import _ from 'lodash';
import 'icheck';

const ROWS_CONTAINER = '#tree-nodes-rows';
const SELECTED_CLASS = 'table__row--selected';
const Status = Object.freeze({
  SORTING: 0,
  SELECTION_ZERO: 1,
  SELECTION_SINGLE: 2,
  SELECTION_MULTIPLE: 3
});
export default class Datatable {
  constructor(browse, props = {}) {
    this.browse = browse;
    this.selectedIds = props.selectedIds || [];
    // this.styleSelectRows();
    this.props = props;
    this.status = Status.SORTING;
    this.browse.groupToolbar.showSortingActionsButtons();

    this.initializeSortable();
    this.initializeRowEvents();
    this.initializeCheckBoxes();
    this.initializeTooltips();
  }

  handleNodeLinkClick(e) {
    e.preventDefault();
    e.stopPropagation();

    const $a = $(e.target);
    const node = this.browse.tree.findNode($a.data('uuid')).data;
    this.browse.visitNode(node);
  }

  handleRowClick(e) {
    const $rowEl = $(e.target.closest('tr[data-uuid]'));
    if(this.status == Status.SORTING) {
      $rowEl.find('a[data-uuid]').click();
    } else {
      const rowUuid = $rowEl.data('uuid');
      // this.styleDeselectRows();
      if(this.selectedIds.includes(rowUuid)){
        this.deselectRows([rowUuid]);
      } else {
        this.selectRows([rowUuid]);
      }
      if (this.selectedIds.length === 1) {
        this.setStatusSingleSelection();
      } else {
        this.setStatusMultipleSelection();
      }
      // this.styleSelectRows();
    }
  }

  selectRows(rowsUuids) {
    this.selectedIds = _.concat(this.selectedIds, rowsUuids);
    this.setCheckboxesChecked(rowsUuids, true);
  }

  deselectRows(rowsUuids) {
    _.pullAll(this.selectedIds, rowsUuids);
    this.setCheckboxesChecked(rowsUuids, false);
  }

  deselectAll() {
    this.deselectRows(this.getUuids());
    this.selectedIds = [];
  }

  setCheckboxesChecked(rowsUuids, boolState) {
    // native chackboxes
    const $checkboxElements = $($.map(rowsUuids, rowUuid => {
      return this.$rowsContainer.find(`tr[data-uuid="${rowUuid}"] input[type="checkbox"]`).get();
    }));
    $checkboxElements.prop('checked', boolState);

    // iCheck checkboxes
    const $iCheckElements = $($.map(rowsUuids, rowUuid => {
      return this.$rowsContainer.find(`tr[data-uuid="${rowUuid}"] [class*="icheckbox_"]`).get();
    }));
    if(boolState === true) {
      $iCheckElements.addClass('checked');
    } else {
      $iCheckElements.removeClass('checked');
    }
  }

  initializeTooltips() {
    $(document).ready(function() {
      $('tr[data-uuid] .title').each(function() {
        // if text truncated add tooltip
        if(this.offsetWidth < this.scrollWidth && !$(this).attr('data-toggle')) {
          $(this).attr('data-toggle', 'tooltip');
          if(!$(this).attr('title')) {
            $(this).prop('title', $(this).text());
          }
        }
      });
    });
  }

  initializeSortable() {
    if (this.browse.state.readOnly) { return; }
    if (this.$rowsContainer.length === 0) { return; }
    this.sortable = new Sortable(ROWS_CONTAINER, this.bundle_id, this.current_uuid);
    this.enableDragHandles();
  }

  initializeSelectable() {
    // this.selectable = new Selectable(ROWS_CONTAINER)
  }

  initializeRowEvents() {
    this.$rowsContainer.find('a[data-uuid]').on('click', this.handleNodeLinkClick.bind(this));
    this.$rowsContainer.find('tr[data-uuid]').on('click', this.handleRowClick.bind(this));
    $(document).on('datatable:selection:enabled', this.handleSelectionEnabling.bind(this));
    $(document).on('datatable:selection:disabled', this.handleSelectionDisabling.bind(this));
  }

  initializeCheckBoxes() {
    $('input[type="checkbox"]').iCheck({
      checkboxClass: 'icheckbox_square-aero',
      lableHover: false,
      cursor: true
    });
  }

  get $rowsContainer() {
    return $(ROWS_CONTAINER);
  }

  get bundle_id() {
    return this.browse.state.bundle_id;
  }

  get current_uuid() {
    return this.browse.state.current_uuid;
  }

  styleSelectRows(rows=this.getSelectedRowsSelector()) {
    $(rows).addClass(SELECTED_CLASS);
  }

  styleDeselectRows(rows=this.getSelectedRowsSelector()) {
    $(rows).removeClass(SELECTED_CLASS);
  }

  getSelectedRowsSelector() {
    return this.selectedIds.map( id => `[data-uuid="${id}"]` ).join();
  }

  handleSelectionEnabling() {
    this.status = Status.SELECTION_ZERO;
    this.disableSorting();
    this.enableSelection();
  }

  handleSelectionDisabling() {
    this.status = Status.SORTING;
    this.disableSelection();
    this.enableSorting();
  }

  resetToolbar() {
    // this.status = Status.SORTING;
    this.browse.groupToolbar.reset();
  }

  showCheckboxes() {
    this.$rowsContainer.find('tr[data-uuid] .row__checkbox').removeClass('dam-hidden');
  }

  hideCheckboxes() {
    this.$rowsContainer.find('tr[data-uuid] .row__checkbox').addClass('dam-hidden');
  }

  enableSelection() {
    this.resetToolbar();
    this.$rowsContainer.find('tr[data-uuid]').addClass('table__row--selectable');
    this.browse.groupToolbar.showZeroElementsActionsButtons();
    this.showCheckboxes();
  }

  setStatusSingleSelection() {
    this.resetToolbar();
    this.status = Status.SELECTION_SINGLE;
    this.browse.groupToolbar.showSingleElementActionsButtons();
  }

  setStatusMultipleSelection() {
    this.resetToolbar();
    this.status = Status.SELECTION_MULTIPLE;
    this.browse.groupToolbar.showMultipleElementsActionsButtons();
  }

  disableSelection() {
    this.resetToolbar();
    this.deselectAll();
    this.hideCheckboxes();
  }

  enableSorting() {
    // this.initializeSortable();
    this.enableDragHandles();
    this.browse.groupToolbar.showSortingActionsButtons();
  }

  disableSorting() {
    this.disableDragHandles();
    // this.destroySortable();
  }

  moveRow(direction){
    const selectedRowUuid = this.selectedIds[0];
    const $rowEl = this.getRowEl(selectedRowUuid);

    if(direction === 'down' && !$rowEl.is(':last-child')){
      $rowEl.insertAfter($rowEl.next());
    } else if (direction === 'up' && !$rowEl.is(':first-child')) {
      $rowEl.insertBefore($rowEl.prev());
    } else {
      return;
    }

    this.sortable.submitArrange();

  }

  getRowEl(uuid){
    return $(this.$rowsContainer.find(`tr[data-uuid="${uuid}"]`));
  }

  getUuids(rows=null) {
    rows = rows || this.$rowsContainer.find('tr[data-uuid]');
    const uuids = $(rows).map((index, el) => $(el).data('uuid'));
    return _.compact(uuids);
  }

  enableDragHandles() {
    $('.drag-handle').closest('tr[data-uuid]').addClass('datatable__row--movable');
  }

  disableDragHandles() {
    $('.drag-handle').closest('tr[data-uuid]').removeClass('datatable__row--movable');
  }

  destroy() {
    this.$rowsContainer.find('a[data-uuid]').off('click');
    this.destroySortable();
  }

  destroySortable() {
    if (this.sortable) {
      this.sortable.destroy();
      this.sortable = undefined;
    }
  }
}

import * as d3 from 'd3';
import classNames from 'classnames';
import _ from 'lodash';

import ContextMenu from './ContextMenu';
import { diagonal } from './utils';

const margin = { top: 20, right: 250, bottom: 20, left: 250 };
const duration = 750;

// Collapse the node and all it's children
const collapse = (d) => {
  if (d.children) {
    d._children = d.children;
    d.children = null;
  }
};

const deepCollapse = (d) => {
  (d.children || d._children || []).forEach(deepCollapse);
  if (d.children) {
    d._children = d.children;
    d._children.forEach(collapse);
    d.children = null;
  }
};

const explode = (d) => {
  if (d._children) {
    d.children = d._children;
    d._children = null;
  }
};


const handleEventWithContext = (context, callback) => (
  function() {
    // Note: `this` refer to the SVG event element.
    const cbArgs = Array.from(arguments).concat(this);
    context[callback](...cbArgs);
  }
);

const innerHeight = 2000;

export default class Dendrogram {
  constructor(svg, data, props = {}) {
    this.svg = svg.classed('Dendrogram', true);
    this.width = +svg.attr('width') - margin.right - margin.left;
    this.height = +svg.attr('height') - margin.top - margin.bottom;

    function zoomed() {
      gLayer.attr('transform', d3.event.transform);
    }

    this.zoom = d3.zoom().scaleExtent([1 / 2, 4]).on('zoom', zoomed);

    svg.append('rect')
      .attr('width', svg.attr('width'))
      .attr('height', innerHeight)
      .style('fill', 'none')
      .style('pointer-events', 'all')
      .call(this.zoom);

    const gLayer = this.gLayer = this.svg.append('g')
      .attr('height', innerHeight)
      .attr('transform', `translate(${margin.left},${margin.top})`);

    this.data = data;
    this.props = props;
    this.counter = 0;
    this.state = { selectedNode: null };

    this.initializeTree();
    this.initializeMenu();
  }

  destroy() {
    this.width = undefined;
    this.height = undefined;
    this.svg = undefined;
    this.data = undefined;
    this.props = undefined;
    this.counter = undefined;
    this.state.selectedNode = undefined;
    this.tree = undefined;
    this.root = undefined;
  }

  initializeTree() {
    // declares a tree layout and assigns the size
    this.tree = d3.cluster().size([innerHeight, this.width]);

    // Assigns parent, children, height, depth
    this.root = d3.hierarchy(this.data);
    this.root.x0 = innerHeight / 2;
    this.root.y0 = 0;

    // Collapse after the second level
    (this.root.children || []).forEach(deepCollapse);
  }

  // Initialize the node context menu
  initializeMenu() {
    if (this.props.nodeContextMenu) {
      this.menu = new ContextMenu(this.props.nodeContextMenu);
    }
  }

  // Open/Close node children
  toggleNode(d) {
    if (d.children) {
      d._children = d.children;
      d.children = null;
    } else {
      d.children = d._children;
      d._children = null;
    }

    this.update(d);
  }

  toggleCurrentNode(d) {
    this.state.selectedNode = d;

    // NOTE: Deselection logic, actually removed
    // if (this.state.selectedNode && this.state.selectedNode.id === d.id) {
    //   this.state.selectedNode = null;
    // } else {
    //   this.state.selectedNode = d;
    // }

    if (this.props.onNodeClick) {
      this.props.onNodeClick(d);
    }

    this.update(d);
  }

  handleNodeDoubleClick(d) {
    if (this.props.onNodeDoubleClick) {
      this.props.onNodeDoubleClick(d);
    }
  }

  handleNodeClick(d) {
    this.toggleCurrentNode(d);
  }

  handleNodeContexMenu(d, index, _, el) {
    if (this.props.nodeContextMenu) {
      this.menu.show(d, index, el);
    }
  }

  isCurrentNode(node) {
    return (this.state.selectedNode || {}).id === node.id;
  }

  hasChildren(node) {
    return (node.children || []).length > 0  || (node._children || []).length > 0;
  }

  assignNodeId(node) {
    if (node.id) { return node.id; }
    node.id = node.data.uuid || ++this.counter;
  }

  update(source) {
    // Assigns the x and y position for the nodes
    const treeData = this.tree(this.root);

    // Compute the new tree layout.
    const nodes = treeData.descendants();
    const links = treeData.descendants().slice(1);

    // Normalize for fixed-depth into tree.
    nodes.forEach((d) => { d.y = d.depth * 220; });

    // ****************** Nodes section ***************************

    const nodeClass = (node) => {
      return classNames('Dendrogram__node', {
        'Dendrogram__node--active': this.isCurrentNode(node),
        'Dendrogram__node--withChildren': this.hasChildren(node),
      });
    };

    // Update the nodes...
    // Each element in the data array is paired with the corresponding node in the selection
    const node = this.gLayer.selectAll('g.Dendrogram__node')
      .data(nodes, (d) => (this.assignNodeId(d)));

    // Enter any new modes at the parent's previous position.
    const nodeEnter = node.enter().append('g')
      .attr('class', nodeClass)
      .attr('transform', () => (`translate(${source.y0},${source.x0})`))
      .on('dblclick', handleEventWithContext(this, 'handleNodeDoubleClick'))
      .on('click', handleEventWithContext(this, 'handleNodeClick'))
      .on('contextmenu', handleEventWithContext(this, 'handleNodeContexMenu'));

    // Add a Circle for the resource_typology nodes
    nodeEnter.filter((d) => (d.data.typology == 'resource_typology'))
      .append('circle')
      .attr('class', 'Dendrogram__glyph Dendrogram__glyph--circle')
      .attr('r', 1e-6);

    // Add a Square for the group_typology nodes
    nodeEnter.filter((d) => (d.data.typology == 'group_typology'))
      .append('rect')
      .attr('class', 'Dendrogram__glyph Dendrogram__glyph--rect')
      .attr('x', -9)
      .attr('y', -9)
      .attr('height', 1e-6)
      .attr('width', 1e-6);

    // Add labels for the nodes
    nodeEnter.append('text')
      .attr('class', 'Dendrogram__text')
      .attr('dy', '.35em')
      .attr('x', -15)
      .attr('text-anchor', 'end')
      .text((d) => (d.data.name));

    // UPDATE
    const nodeUpdate = nodeEnter.merge(node);

    // Transition to the proper position for the node
    nodeUpdate.attr('class', nodeClass)
      .transition()
      .duration(duration)
      .attr('transform', (d) => (`translate(${d.y},${d.x})`));

    // Update the node attributes and style
    nodeUpdate
      .filter((d) => (d.data.typology == 'resource_typology'))
      .select('.Dendrogram__glyph--circle')
      .attr('class', 'Dendrogram__glyph Dendrogram__glyph--circle')
      .attr('r', 10);

    // Update the node attributes and style
    nodeUpdate
      .filter((d) => (d.data.typology == 'group_typology'))
      .select('.Dendrogram__glyph--rect')
      .attr('class', 'Dendrogram__glyph Dendrogram__glyph--rect')
      .attr('height', 18)
      .attr('width', 18);

    // Remove any exiting nodes
    const nodeExit = node.exit().transition()
      .duration(duration)
      .attr('transform', () => (`translate(${source.y},${source.x})`))
      .remove();

    // On exit reduce the node circles size to 0
    nodeExit.select('.Dendrogram__glyph')
      .attr('r', 1e-6);

    // On exit reduce the opacity of text labels
    nodeExit.select('text')
      .style('fill-opacity', 1e-6);

    // ****************** links section ***************************

    // Update the links...
    // Each element in the data array is paired with the corresponding node in the selection
    const link = this.gLayer.selectAll('path.Dendrogram__link')
      .data(links, function(d) { return d.id; });

    // Enter any new links at the parent's previous position.
    const linkEnter = link.enter().insert('path', 'g')
      .attr('class', 'Dendrogram__link')
      .attr('d', () => {
        const o = { x: source.x0, y: source.y0 };
        return diagonal(o, o);
      });

    // UPDATE
    const linkUpdate = linkEnter.merge(link);

    // Transition back to the parent element position
    linkUpdate.transition()
      .duration(duration)
      .attr('d', (d) => (diagonal(d, d.parent)));

    // Remove any exiting links
    const linkExit = link.exit().transition() // eslint-disable-line
      .duration(duration)
      .attr('d', () => {
        const o = { x: source.x, y: source.y };
        return diagonal(o, o);
      })
      .remove();

    // Store the old positions for transition.
    nodes.forEach(function(d){
      d.x0 = d.x;
      d.y0 = d.y;
    });
  }

  render() {
    this.update(this.root);
  }

  findNode(id, subtree = null) {
    const structure = subtree || this.root;
    if (structure.id === id) { return structure; }
    const children = structure.children || structure._children || [];

    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      const result = this.findNode(id, child);
      if (result) { return result; }
    }

    return false;
  }

  addNode(parentNode, nodeDatum) {
    const newNode = d3.hierarchy({children: [], ...nodeDatum});

    newNode.depth = parentNode.depth + 1;
    newNode.height = parentNode.height - 1;
    newNode.parent = parentNode;
    this.assignNodeId(newNode);

    // Untoggle the parentNode if collapsed
    if (parentNode._children && parentNode._children.length > 0) {
      this.toggleNode(parentNode);
    }

    // Initialize an empty array of children if the parent was a leaf
    if (!parentNode.children) {
      parentNode.children = [];
      parentNode.data.children = [];
    }

    parentNode.children.push(newNode);
    parentNode.data.children.push(newNode.data);

    this.update(parentNode);
  }

  // Update a current d3 node with a new plain datum
  updateNode(nodeDatum, uuid) {
    const oldNode = this.findNode(uuid);
    const parentNode = oldNode.parent || this.root;

    // Deleted node
    if (!nodeDatum) {
      parentNode.children = parentNode.children || [];
      _.remove((parentNode.children || []), (node) => (node.data.uuid === uuid));
      if (parentNode.children.length === 0) {
        parentNode.children = null;
      }
      this.update(parentNode);
      return;
    }

    if (uuid == this.root.id) { return; }

    const newNode = d3.hierarchy({ children: [], ...nodeDatum });
    const oldSiblings = parentNode.children;
    const oldDataSiblings = parentNode.data.children;
    const adjustChildren = (node, { depth }) => {
      node.depth = depth;
      (node.children || node._children || []).forEach((child) => {
        adjustChildren(child, { depth: depth + 1 });
      });
      collapse(node);
    };

    newNode.parent = parentNode;
    // newNode.depth = oldNode.depth;
    newNode.height = oldNode.height;
    newNode.id = oldNode.id;
    newNode.x0 = oldNode.x0;
    newNode.y0 = oldNode.y0;

    adjustChildren(newNode, { depth: oldNode.depth });
    explode(newNode);

    parentNode.children = [];
    parentNode.data.children = [];

    oldSiblings.forEach((node) => {
      parentNode.children.push(
        node.id === nodeDatum.uuid
          ? newNode
          : node
      );
    });

    oldDataSiblings.forEach((datum) => {
      parentNode.data.children.push(
        datum.uuid === nodeDatum.uuid
          ? nodeDatum
          : datum
      );
    });

    this.update(parentNode);
  }

  removeNode(uuid) {
    this.updateNode(null, uuid);
  }
}

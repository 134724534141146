import AssetsIndex from './assets/Index';
import AssetsNew from './assets/New';
import BundlesEdit from './bundles/Edit';
import BundlesIndex from './bundles/Index';
import BundlesStructure from './bundles/Structure';
import BundlesBrowse from './bundles/Browse';
import BundlesSearch from './bundles/Search';
import ChannelsEdit from './channels/Edit';
import ErrataEdit from './errata/Edit';
import LegacyImportsNew from './legacy_imports/New';
import ResourceTemplatesEdit from './resource_templates/Edit';
import ResourcesShow from './resources/Show';
import ResourcesEdit from './resources/Edit';
import ResourcesIndex from './resources/Index';
import ResourcesIndexDraft from './resources/IndexDraft';
import ResourcesIndexLive from './resources/IndexLive';
import VersionsIndex from './versions/Index';

// Rails controllers' actions mapping
export default {
  'assets#index': AssetsIndex,
  'assets#new': AssetsNew,
  'bundles#edit': BundlesEdit,
  'bundles#index': BundlesIndex,
  'bundles#structure': BundlesStructure,
  'bundles#browse': BundlesBrowse,
  'bundles#search': BundlesSearch,
  'channels#edit': ChannelsEdit,
  'default_channels#edit': ChannelsEdit,
  'errata#edit': ErrataEdit,
  'legacy_imports#new': LegacyImportsNew,
  'resource_templates#edit': ResourceTemplatesEdit,
  'resources#show': ResourcesShow,
  'resources#edit': ResourcesEdit,
  'resources#index': ResourcesIndex,
  'resources#index_draft': ResourcesIndexDraft,
  'resources#index_live': ResourcesIndexLive,
  'versions#index': VersionsIndex
};

import Modal from '../Modal';
import { spinner } from '../Spinner';
// import QueryMetaSearch from '../../components/QueryMetaSearch';
import { searchModalResources, addToGroup } from '../../requests/resources';

export default class AddResourcesModal extends Modal {
  get modalFooterButtons() {
    return [
      {
        content: I18n.t('admin.actions.close'),
        onClick: 'close',
        className: 'btn btn-default'
      },
      // {
      //   content: I18n.t('admin.actions.create'),
      //   onClick: (e) => {
      //     e.preventDefault();
      //     this.handleFormSubmit();
      //   },
      //   className: 'btn btn-primary',
      //   id: 'add-resources-node-submit'
      // }
    ];
  }

  get $resourcesSearch() {
    return this.$.find('.add-resources-modal-search:first');
  }

  get $resourcesContent() {
    return this.$.find('.add-resources-modal-content:first');
  }

  get $searchForm() {
    return this.$resourcesSearch.find('form');
  }

  get $searchInput() {
    return $('#meta_search_form_query_string');
  }

  get $searchButton() {
    return this.$resourcesSearch.find('[type="submit"]:not([data-reset="true"])');
  }

  get $resetButton() {
    return this.$resourcesSearch.find('[data-reset="true"]');
  }

  get $addGroupButtons() {
    return this.$resourcesContent.find('[data-add-group="true"]');
  }

  constructor(props = {}) {
    const { open, ...otherProps } = props;
    super({ open, domId: 'add-resources-node-modal' });

    this.currentNode = null;
    this.bundle_id = otherProps.bundle_id;
    this.onSubmit = otherProps.onSubmit;
  }

  initializeModal(payload) {
    this.update({
      content: payload,
      footerButtons: this.modalFooterButtons
    });

    // this.destroyMetaSearch();
    // this.queryMetaSearch = new QueryMetaSearch(payload.autocompleter_list);

    this.bindButtons();
  }

  resetNode() {
    this.unbindButtons();
    // this.destroyMetaSearch();
    this.currentNode = null;
  }

  // display the modal given the future parent node
  loadNode(node) {
    if (node && node === this.currentNode) {
      this.open();
      return;
    }

    this.resetNode();
    this.currentNode = node;

    this.update({
      title: I18n.t('admin.nodes.add_resources', { name: node.name }),
      content: spinner(),
      footerButtons: []
    });

    this.open();

    searchModalResources({ format: 'html', meta_search_form: { target_parent_uuid: node.uuid } })
      .done((payload) => { this.initializeModal(payload); });
  }

  serializedSearchForm(initialValue = {}) {
    return this.$searchForm.serializeArray().reduce((p, c) => (Object.assign(p, { [c.name]: c.value })), initialValue);
  }

  handleAddGroupButtonClick(e) {
    e.preventDefault();
    e.stopPropagation();

    const $a = e.target.tagName === 'A' ? $(e.target) : $(e.target).parent();
    const $box = $a.parents('.panel:first');
    const resourceId = $a.data('resourceId');

    addToGroup(resourceId, this.currentNode.uuid).done(() => {
      $(document).trigger('tree:node:changed', [this.currentNode]);
      $box.hide(200);
    });
  }

  handleSearch(e) {
    e.preventDefault();
    e.stopPropagation();

    const data = this.serializedSearchForm({ format: 'html' });

    searchModalResources(data)
      .done((payload) => { this.initializeModal(payload); });
  }

  handleReset(e) {
    e.preventDefault();
    e.stopPropagation();

    const data = this.serializedSearchForm({ format: 'html', reset: true });

    searchModalResources(data)
      .done((payload) => { this.initializeModal(payload); });
  }

  bindButtons() {
    this.$searchButton.on('click', this.handleSearch.bind(this));
    this.$resetButton.on('click', this.handleReset.bind(this));
    this.$addGroupButtons.on('click', this.handleAddGroupButtonClick.bind(this));
  }

  unbindButtons() {
    if (this.$searchButton) { this.$searchButton.off('click'); }
    if (this.$resetButton) { this.$resetButton.off('click'); }
    if (this.$addGroupButtons) { this.$addGroupButtons.off('click'); }
  }

  // destroyMetaSearch() {
  //   if (this.queryMetaSearch) {
  //     this.queryMetaSearch.destroy();
  //     this.queryMetaSearch = undefined;
  //   }
  // }

  destroy() {
    this.resetNode();
    this.$.remove();
  }
}

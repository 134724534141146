import Editables from '../../components/Editables';

export default class PillsMetaSearch {
  constructor(container, query_string_input, combinator_input) {
    this.$container = $(container);

    this.$query_string_input = $(query_string_input);
    this.$combinator_input = $(combinator_input);

    this.$category = this.$container.find('#pill_category');
    this.$combinator = this.$container.find('#pill_combinator');
    this.$blister = this.$container.find('.meta-blister');
    this.$template = this.$container.find('.meta-pill--template');

    // Init selectors
    this.$category.select2();
    this.$combinator.select2();

    // Init pills from backend
    this.$blister.find('.meta-pill').each((i,e) => { this.bindPill($(e)); });

    // Update hidden input holding serialized query string before submit
    this.$blister.closest('form').bind('submit', () => {
      this.$query_string_input.val(this.blisterToQuery(this.$blister));
      this.$combinator_input.val(this.$combinator.val());
    });

    // Create new pill from the selection when the big button is clicked
    $('.meta-pill__add').click(this.newPill.bind(this));
  }

  newPill() {
    var $selection = this.$category.select2().find(':selected');
    var $newPill = this.$template.clone();

    $newPill.find('.meta-pill__subject').
      attr('title', $selection.data('tooltip')).
      attr('data-value', $selection.val()).
      html($selection.data('name'));

    const defaultPredicate = $selection.data('predicates').find(x => x.value === 'cont') || $selection.data('predicates')[0];

    $newPill.find('.meta-pill__verb').
      attr('data-source', $selection.attr('data-predicates')).
      attr('data-value', defaultPredicate.value).
      text(defaultPredicate.text);

    if ($selection.data('complements') != null){
      const defaultComplement = $selection.data('complements').find(x => x.value === 'true') || $selection.data('predicates')[0];

      $newPill.find('.meta-pill__complement').
        attr('data-type', 'select2').
        attr('data-source', $selection.attr('data-complements')).
        attr('data-value', defaultComplement.value).
        text(defaultComplement.text);
    }

    this.$blister.append($newPill);

    this.bindPill($newPill);

    $newPill.removeClass('meta-pill--template').addClass('meta-pill');
  }

  bindPill($pill) {
    new Editables($pill.find('.meta-pill__verb'), {});
    new Editables($pill.find('.meta-pill__complement'), {});
    $pill.find('.meta-pill__delete').click((event) => {
      $(event.target).closest('.meta-pill').remove();
    });
  }

  blisterToQuery($blister) {
    var clauses = [];
    $blister.find('.meta-pill').each((i,e) => { clauses.push(this.pillToClause($(e))); });
    return clauses.join(', ');
  }

  pillToClause($pill) {
    var data = {
      subject: $pill.find('.meta-pill__subject').data('value'),
      predicate: $pill.find('.meta-pill__verb').editable('getValue', true),
      value: $pill.find('.meta-pill__complement').editable('getValue', true)
    };

    var subject, predicate, value;

    subject = data['subject'];
    predicate = data['predicate'];
    value = `'${data['value']}'`;

    var clause = [subject, predicate, value].join(' ');

    return clause;
  }

  destroy() {
    this.$category.select2('destroy');
    this.$combinator.select2('destroy');
  }
}

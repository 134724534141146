import Base from '../base';
import PillsMetaSearch from '../../components/PillsMetaSearch';
import Modal from '../../components/Modal';
import Spinner from '../../components/Spinner';

export default class Index extends Base {
  load() {
    this.pillsMetaSearch = new PillsMetaSearch('.meta-container', '#meta_search_form_query_string', '#meta_search_form_combinator');
    this.modal = new Modal();

    this.spinner = new Spinner();

    var that = this;

    $('[data-info="resource"]').on('click', function(event) {
      event.preventDefault();
      event.stopPropagation();

      that.showDetailsModal(
        Routes.details_modal_admin_resource_path,
        event.currentTarget.dataset.resourceName,
        event.currentTarget.dataset.resourceId
      );
    });

    $('[data-info="wrapper"]').on('click', function(event) {
      event.preventDefault();
      event.stopPropagation();

      that.showDetailsModal(
        Routes.details_modal_admin_wrapper_path,
        event.currentTarget.dataset.wrapperName,
        event.currentTarget.dataset.wrapperId
      );
    });
  }

  showDetailsModal(path, name, id) {
    const modalButtons = [
      {
        content: I18n.t('admin.actions.close'),
        onClick: 'close',
        className: 'btn btn-default'
      }
    ];

    this.modal.update({
      title: name,
      content: this.spinner.render(),
      footerButtons: modalButtons
    });

    this.modal.open();

    $.get(path(id))
      .fail(() => { this.modal.updateContent('Error: Something went wrong...'); })
      .done((payload) => {
        this.modal.updateContent(payload);
      });
  }

  destroy() {
    this.modal.destroy();

    if (this.pillsMetaSearch) {
      this.pillsMetaSearch.destroy();
      this.pillsMetaSearch = undefined;
    }
  }
}

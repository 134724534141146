import Select from '../../components/Select2';

const template = `
  <div class="DataTable__toolbar row">
    <div class="DataTable__toolbarCtas col-md-8"></div>
    <div class="DataTable__toolbarInfo col-md-4 text-right"></div>
  </div>
`;

const ctaDisabledClass = 'DataTable__toolbarCta--disabled';

const handleCallToAction = (action, $el) => (event) => {
  event.preventDefault();
  event.stopPropagation();

  if (!$el.prop('disabled')) { action($el); }
};

export default class ToolBar {
  constructor(datatable, { selector, actions }) {
    this.dt = datatable;
    this.selector = selector;
    this.actions = actions;
    this.initialRender();
    this.dt.on('DataTable:Selection', this.handleSelection.bind(this));
  }

  get $() {
    return $(this.selector);
  }

  get $toolbar() {
    return this.$.children('.DataTable__toolbar');
  }

  get $ctasSection() {
    return this.$toolbar.find('.DataTable__toolbarCtas');
  }

  get $infoSection() {
    return this.$toolbar.find('.DataTable__toolbarInfo');
  }

  get $ctas() {
    return this.$toolbar.find('.DataTable__toolbarCta');
  }

  get $buttonCtas() {
    return this.$toolbar.find('.DataTable__toolbarCta--button');
  }

  get $dropDownCtas() {
    return this.$toolbar.find('.DataTable__toolbarCta--dropdown');
  }

  handleSelection() {
    if (this.dt.hasSelectedIds()) {
      this.$infoSection.html(`Ci sono ${this.dt.selectedIds.length} asset selezionati`);
      this.enableCtas();
    } else {
      this.$infoSection.html('Non ci sono asset selezionati');
      this.disableCtas();
    }
  }

  buildButtonCta({ label, action, ...otherProps }) {
    const classes = ['DataTable__toolbarCta', 'DataTable__toolbarCta--button'];
    classes.push(otherProps.class ? otherProps.class : 'btn btn-default');
    const $button = $(`<button class="${classes.join(' ')}" href="#cta">${label}</button>`);

    $button.on('click', handleCallToAction(action, $button));

    return $button;
  }

  buildDropDownCta({ label, action, collection, ...otherProps }) {
    const classes = ['DataTable__toolbarCta', 'DataTable__toolbarCta--dropdown'];
    if (otherProps.class) { classes.push(otherProps.class); }

    const $select = $(`
      <select class="${classes.join(' ')}" data-placeholder="${label}">
        <option></option>
        <option value="0">Generica</option>
        ${collection.map(({id, name}) => (`<option value="${id}">${name}</option>`)).join()}
      </select>
    `);

    $select.on('change', handleCallToAction(action, $select));

    return $select;
  }

  buildCtas() {
    return this.actions.map((action) => (
      action.collection
        ? this.buildDropDownCta(action)
        : this.buildButtonCta(action)
    ));
  }

  disableCtas() {
    this.$ctas.each((idx, el) => {
      $(el).prop('disabled', true).addClass(ctaDisabledClass);
    });
  }

  enableCtas() {
    this.$ctas.each((idx, el) => {
      $(el).prop('disabled', false).removeClass(ctaDisabledClass);
    });
  }

  initialRender() {
    const $template = $(template);
    this.$.html($template);

    this.buildCtas().forEach(($cta) => {
      this.$ctasSection.append($cta);
    });

    Select.initByElement(this.$dropDownCtas, { width: '35%', allowClear: true });

    this.handleSelection();
  }

  destroy() {
    this.dt.off('DataTable:Selection', this.handleSelection.bind(this));
    this.$buttonCtas.off('click');
    this.$dropDownCtas.off('change');
    this.dt = undefined;
  }
}

import I18n from 'i18n-js/index.js.erb';
import Dropzone from 'dropzone';

import { computeHash } from './hasher';
import { initUpload, prepHeaders, stopUpload } from './rails_api';

Dropzone.autoDiscover = false;

// NOTE: we're taking lang from html because gon/app/I18n ain't properly inited yet, here.
Object.assign(Dropzone.prototype.defaultOptions, I18n.t('Dropzone', { locale: $('html').attr('lang') }));

const defaultOptions = {
  maxFilesize: 2048, // 2Gb
  // addRemoveLinks: true, // TODO: implement callbacks
  method: 'PUT',
  // NOTE: S3 kinda sucks, so no chunking.
  chunking: false,
  // NOTE: dropzone defaults to a multipart body. This is problematic when we want to
  //   use the Content-MD5 header to sign requests and guarantee file identity/integrity
  //   when performing direct uploads. We mokey patch sending() to override the behaviour.
  sending: function(file, xhr) {
    xhr.timeout = null;
    var _send = xhr.send;
    xhr.send = function() {
      _send.call(xhr, file);
    };
  },
  // Set all callbacks on init.
  accept: function(file, done) {
    // Add all contextual data to file object.
    file.railsApi = this.options.railsApi;
    // Pass the file object along in a promise chain.
    computeHash(file)
      .then(prepHeaders)
      .then(initUpload)
      .then(() => { done(); })
      // FIXME we're assuming only the initUpload can fail - improve!
      .catch((file) => { done(file.initUploadRequest.responseJSON.error.message); });
  },
  init: function() {
    // this.on('addedfile', (file) => { // FIXME should be async
    //   // this.processFile(file);
    // });
    this.on('processing', (file) => {
      if(file.initUploadRequest.responseJSON.data.fallbackUrl) {
        var anchorElement = file.previewElement.querySelector('.dz-filename > a');
        anchorElement.setAttribute('href', file.initUploadRequest.responseJSON.data.fallbackUrl);
      }
      // Setup dynamic per-file upload url and headers:
      this.options.url = file.presignedPutUrl;
      // FIXME Ruby S3 API isn't signing Content-Length for some reason
      var signatureHeaders = Object.assign({}, file.signatureHeaders, {'Content-Length': undefined});
      console.log(signatureHeaders);
      this.options.headers = signatureHeaders;
    });
    this.on('canceled', (file) => {
      console.log("TODO: delete record on server + asset on s3 not used already.");
    });
    this.on('removedfile', (file) => {
      console.log("TODO: delete record on server + asset on s3 not used already.");
    });
    this.on('complete', (file) => { // FIXME should be async anon func?
      if (file.accepted) { stopUpload(file); }
    });
    this.on('error', (file, errorMessage, xhr) => {
      if(file.initUploadRequest.status == 409) {
        var anchorElement = file.previewElement.querySelector('.dz-filename > a');
        anchorElement.setAttribute('href', file.initUploadRequest.responseJSON.data.fallbackUrl);
        // Not an actual error, so...
        file.status = Dropzone.SUCCESS;
        if (file.previewElement) { file.previewElement.classList.remove('dz-error'); }
        this.emit('success', file); // no response passed as, technically, no upload happened
        this.emit('complete', file);
      }
      if (file.initUploadRequest.responseJSON.error) { // check allows files canceled by user to pass through
        if (file.initUploadRequest.responseJSON.error.type == 'notice') {
          var errorMessageElement = file.previewElement.querySelector('.dz-error-message');
          errorMessageElement.classList.add('dz-error-message--notice');
        }
      }
    });
  },
  previewTemplate: `
    <div class="dz-preview dz-file-preview">
      <div class="dz-image"><img data-dz-thumbnail=""></div>
      <div class="dz-details">
        <div class="dz-size"><span data-dz-size=""></div>
        <div class="dz-filename">
          <a target="_blank"><span data-dz-name=""></span></a>
        </div>
      </div>
      <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress=""></span></div>
      <div class="dz-error-message"><span data-dz-errormessage=""></span></div>
      <div class="dz-success-mark"><svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"><title>Check</title><defs></defs><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage"><path d="M23.5,31.8431458 L17.5852419,25.9283877 C16.0248253,24.3679711 13.4910294,24.366835 11.9289322,25.9289322 C10.3700136,27.4878508 10.3665912,30.0234455 11.9283877,31.5852419 L20.4147581,40.0716123 C20.5133999,40.1702541 20.6159315,40.2626649 20.7218615,40.3488435 C22.2835669,41.8725651 24.794234,41.8626202 26.3461564,40.3106978 L43.3106978,23.3461564 C44.8771021,21.7797521 44.8758057,19.2483887 43.3137085,17.6862915 C41.7547899,16.1273729 39.2176035,16.1255422 37.6538436,17.6893022 L23.5,31.8431458 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z" id="Oval-2" stroke-opacity="0.198794158" stroke="#747474" fill-opacity="0.816519475" fill="#FFFFFF" sketch:type="MSShapeGroup"></path></g></svg></div>
      <div class="dz-error-mark"><svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"><title>Error</title><defs></defs><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage"><g id="Check-+-Oval-2" sketch:type="MSLayerGroup" stroke="#747474" stroke-opacity="0.198794158" fill="#FFFFFF" fill-opacity="0.816519475"><path d="M32.6568542,29 L38.3106978,23.3461564 C39.8771021,21.7797521 39.8758057,19.2483887 38.3137085,17.6862915 C36.7547899,16.1273729 34.2176035,16.1255422 32.6538436,17.6893022 L27,23.3431458 L21.3461564,17.6893022 C19.7823965,16.1255422 17.2452101,16.1273729 15.6862915,17.6862915 C14.1241943,19.2483887 14.1228979,21.7797521 15.6893022,23.3461564 L21.3431458,29 L15.6893022,34.6538436 C14.1228979,36.2202479 14.1241943,38.7516113 15.6862915,40.3137085 C17.2452101,41.8726271 19.7823965,41.8744578 21.3461564,40.3106978 L27,34.6568542 L32.6538436,40.3106978 C34.2176035,41.8744578 36.7547899,41.8726271 38.3137085,40.3137085 C39.8758057,38.7516113 39.8771021,36.2202479 38.3106978,34.6538436 L32.6568542,29 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z" id="Oval-2" sketch:type="MSShapeGroup"></path></g></g></svg></div>
    </div>`
};

export default class Uploader extends Dropzone {
  constructor(element, options) {
    super(element, Object.assign(Object.assign({}, defaultOptions), options));
  }
}

import * as SparkMD5 from 'spark-md5';

// Compute (async) the MD5 hash of a JS file object and set it as 'hash' property.
export const computeHash = (file) => {
  var xBrowserSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
  var chunkSize = 1024*1024*10;
  var spark = new SparkMD5.ArrayBuffer();
  var fileReader = new FileReader();
  var chunksNumber = Math.ceil(file.size / chunkSize);
  var currentChunk = 0;

  var loadNextChunk = function () {
    var chunkStart = currentChunk * chunkSize;
    var chunkEnd = ((chunkStart + chunkSize) >= file.size) ? file.size : chunkStart + chunkSize;
    fileReader.readAsArrayBuffer(xBrowserSlice.call(file, chunkStart, chunkEnd));
  };

  return new Promise ( function(resolve, reject){
    fileReader.onload = function (event) {
      spark.append(event.target.result);
      currentChunk++;
      if (currentChunk < chunksNumber) {
        loadNextChunk();
      } else {
        file.hash = spark.end();
        resolve(file);
      }
    };
    fileReader.onerror = reject;
    loadNextChunk();
  });
};

// Convert a string from hex to base 64.
export const hexToBase64 = (hexstring) => {
  return btoa(hexstring.match(/\w{2}/g).map(function(a) {
    return String.fromCharCode(parseInt(a, 16));
  }).join(''));
};

import Selectable from './Selectable';
import ToolBar from './ToolBar';

export default class DataTable {
  constructor(tableSelector, props = {}) {
    this.selectedIds = props.selectedIds || [];
    this.tableSelector = tableSelector;
    this.props = props;
    this.initializeSelectable();
    this.initializeToolBar();
  }

  get $() {
    return $(this.tableSelector);
  }

  get $headRows() {
    return this.$.find('thead > tr, tfoot > tr');
  }

  get $bodyRows() {
    return this.$.find('tbody > tr');
  }

  get availableIds() {
    const ids = [];

    this.$bodyRows.each((idx, el) => {
      ids.push($(el).data('assetId'));
    });

    return ids;
  }

  initializeSelectable() {
    this.selectable = new Selectable(this, this.selectedIds);
  }

  initializeToolBar() {
    if (!this.props.toolbar) { return; }
    this.toolbar = new ToolBar(this, this.props.toolbar);
  }

  hasSelectedIds() {
    return this.selectedIds.length > 0;
  }

  clearSelection() {
    if (this.selectable) {
      this.selectable.deselectAll();
    }
    this.selectedIds = [];
    this.triggerSelectionEvent();
  }

  // Available events:
  // - DataTable:Selection
  trigger(eventKey, args = []) {
    return this.$.trigger(eventKey, args);
  }

  triggerSelectionEvent() {
    this.trigger('DataTable:Selection', [this.selectedIds]);
  }

  on(eventKey, cb) {
    return this.$.on(eventKey, cb);
  }

  off(eventKey, cb) {
    return this.$.off(eventKey, cb);
  }

  destroy() {
    this.tableSelector = undefined;

    if (this.selectable) {
      this.selectable.destroy();
      this.selectable = undefined;
    }

    if (this.toolbar) {
      this.toolbar.destroy();
      this.toolbar = undefined;
    }
  }
}

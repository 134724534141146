import * as d3 from 'd3';
import classNames from 'classnames';

const isDisabled = (menu, node) => {
  if (typeof menu.disabled === 'undefined') { return false; }
  if (typeof menu.disabled === 'function') { return menu.disabled(node); }
  return !!menu.disabled;
};

class ContextMenu {
  constructor(menu, props = {}) {
    this.menu = menu;
    this.props = props;
    this.initializeContainer();
  }

  hide() {
    d3.select('.ContextMenu').style('display', 'none');
  }

  initializeContainer() {
    // create the div element that will hold the context menu
    d3.selectAll('.ContextMenu').data([1])
      .enter()
      .append('div')
      .attr('class', 'ContextMenu');

    // close menu
    d3.select('body').on('click.ContextMenu', () => { this.hide(); });
  }

  show(data, index, elm) {
    d3.selectAll('.ContextMenu').html('');
    const list = d3.selectAll('.ContextMenu').append('ul');
    list.selectAll('li').data(this.menu).enter()
      .append('li')
      .html(function(d) {
        return (typeof d.title === 'string') ? d.title : d.title(data);
      })
      .attr('class', (d) => (classNames('ContextMenu__item', d.className, {
        'ContextMenu__item--disabled': isDisabled(d, data)
      })))
      .on('click', (d) => {
        if (isDisabled(d, data)) { return; }
        d.action(elm, data, index);
        this.hide();
      });

    // the openCallback allows an action to fire before the menu is displayed
    // an example usage would be closing a tooltip
    if (this.props.onShow) {
      if (this.props.onShow(data, index) === false) {
        return;
      }
    }

    // display context menu
    d3.select('.ContextMenu')
      .style('left', (d3.event.pageX - 2) + 'px')
      .style('top', (d3.event.pageY - 2) + 'px')
      .style('display', 'block');

    d3.event.preventDefault();
    d3.event.stopPropagation();
  }
}

export default ContextMenu;

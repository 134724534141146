export const nodeContextMenu = (context) => {
  return [
    {
      title: `<i class="fa fa-fw fa-info" ></i> ${I18n.t('admin.nodes.context_menu.more_info')}`,
      action: (el, d) => { context.showSidebar(d); }
    },
    {
      title: `<i class="fa fa-fw fa-plus" ></i> ${I18n.t('admin.nodes.context_menu.new_child')}`,
      action: (el, d) => { context.showNewChildModal(d); }
    },
    {
      title: `<i class="fa fa-fw fa-plus" ></i> ${I18n.t('admin.nodes.context_menu.new_sibling')}`,
      action: (el, d) => {
        if (d.parent) {
          context.showNewChildModal(d.parent);
        }
      }
    },
    {
      title: `<i class="fa fa-fw fa-share" ></i> ${I18n.t('admin.nodes.context_menu.move_child')}`,
      action: (el, d) => { context.showMoveChildModal(d); }
    },
    {
      className: 'ContextMenu__item--danger',
      title: (node) => (`
        <i class="fa fa-fw fa-trash" ></i> ${
        node.parent
          ? I18n.t('admin.nodes.context_menu.remove_node')
          : I18n.t('admin.nodes.context_menu.remove_tree')
        }
      `),
      action: (el, node) => {
        if (confirm(I18n.t('admin.general.are_you_sure'))) {
          context.handleRemoveNode(node);
        }
      },
      disabled: (node) => (!node.parent)
    }
  ];
};
